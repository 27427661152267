import preval from "preval.macro";
const moment = require("moment");
const dateTimeStamp = preval`module.exports = new Date().toLocaleString('en-US');`;

let cachedVersion = null;
let cachedTime = null;

export default function versionNumber() {
  // Returned cached number
  if (cachedVersion !== null) {
    return cachedVersion;
  }

  // Set number
  cachedVersion = moment(dateTimeStamp).format("YYMMDD.hhmmss");

  return cachedVersion;
}

export function builtTime() {
  // Returned cached number
  if (cachedTime !== null) {
    return cachedTime;
  }

  // Set number
  cachedTime = moment(dateTimeStamp).format("MMMM DD, YYYY hh:mm:ss");

  return cachedTime;
}
