import { useUser } from "context/Auth";
import React, { useEffect, useState } from "react";
import getRelationship from "store/helpers/getRelationship";
import style from "../header.module.scss";
import Slide from "@mui/material/Slide";
import clsx from "clsx";

export default function BlackFridayBar() {
  const user = useUser();
  const [showMessage, setShowMessage] = useState(false);
  const primaryLicense = getRelationship(user, "primaryLicense", null);

  useEffect(() => {
    setShowMessage(true);
  }, []);

  // Black Friday code
  if (
    process.env.REACT_APP_BLACK_FRIDAY === "true" &&
    (!user || (user && !primaryLicense)) &&
    !sessionStorage.hideBlackFriday
  ) {
    const plans =
      process.env.REACT_APP_BLACK_FRIDAY_CYCLE === "all"
        ? "all plans"
        : process.env.REACT_APP_BLACK_FRIDAY_CYCLE === "monthly"
        ? "all monthly plans"
        : "all annual plans";

    return (
      <Slide in={showMessage} direction="down" mountOnEnter unmountOnExit>
        <div className={style.blackFridayBar}>
          <b>Black Friday has arrived!</b> 50% discount on {plans}. Use coupon
          code: <b>{process.env.REACT_APP_BLACK_FRIDAY_COUPON}</b>
          <a
            href={"#"}
            onClick={(e) => {
              e.preventDefault();
              sessionStorage.hideBlackFriday = true;
              setShowMessage(false);
            }}
          >
            Close
          </a>
        </div>
      </Slide>
    );
  }

  // Celebration banner
  if (
    process.env.REACT_APP_DISCOUNT === "true" &&
    (!user || (user && !primaryLicense)) &&
    !sessionStorage.hideCelebration
  ) {
    return (
      <Slide in={showMessage} direction="down" mountOnEnter unmountOnExit>
        <div className={clsx(style.blackFridayBar, style.celebration)}>
          <b>Celebration time!</b> {process.env.REACT_APP_DISCOUNT_PERCENTAGE}%
          discount on all plans. Use coupon code:{" "}
          <b>{process.env.REACT_APP_DISCOUNT_COUPON}</b>
          {process.env.REACT_APP_DISCOUNT_URL !== "" && (
            <a href={process.env.REACT_APP_DISCOUNT_URL} target={"_blank"}>
              More info
            </a>
          )}
          <a
            href={"#"}
            onClick={(e) => {
              e.preventDefault();
              sessionStorage.hideCelebration = true;
              setShowMessage(false);
            }}
          >
            Close
          </a>
        </div>
      </Slide>
    );
  }

  return null;
}
