import React, { useState } from "react";
import style from "pages/Unauthenticated/style.module.scss";
import {
  Button,
  CircularProgress,
  IconButton,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { Link as BrowserLink, useHistory } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useUser } from "context/Auth";
import {
  login,
  afterLoginRedirectPath,
  verifyEmail,
  safeRedirectUrl,
} from "utils/auth";
import MimicSiteContainer from "components/MimicSiteContainer/MimicSiteContainer";
import EmailVerificationRequired from "components/EmailVerificationRequired/EmailVerificationRequired";
import NoPassword from "components/NoPassword/NoPassword";
import queryString from "query-string";
import dlv from "dlv";

export default function Login() {
  const history = useHistory();
  const routeParams = queryString.parse(document.location.search);

  const withHeader = !dlv(routeParams, "from", "").startsWith("/appsumo");

  const [loginError, setLoginError] = useState(null);
  const [verifyingEmail, setVerifyingEmail] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState(dlv(routeParams, "email", ""));
  const [emailVerified, setEmailVerified] = useState(false);
  const [verificationRequired, setVerificationRequired] = useState(false);
  const [createPasswordRequired, setCreatePasswordRequired] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const [password, setPassword] = useState("");

  const user = useUser();

  // Check if authenticated
  if (user !== null) {
    history.replace(afterLoginRedirectPath());

    return null;
  }

  // Verify email first
  const emailVerification = (e = null) => {
    if (e) {
      e.preventDefault();
    }

    if (!email) {
      setLoginError("Please enter your email address");
      return;
    }

    setVerifyingEmail(true);
    setLoginError(null);

    verifyEmail({ email })
      .then(() => {
        setEmailVerified(true);
      })
      .catch((err) => {
        switch (err) {
          case "email_verification_required":
            setVerificationRequired(true);
            break;
          case "no_password_set":
            setCreatePasswordRequired(true);
            break;
          case "no_account_found":
            setLoginError(
              <>
                Couldn't find your Buttonizer account.{" "}
                <Link
                  onClick={() =>
                    (document.location.href = safeRedirectUrl(
                      "/account/intake?email=" + email
                    ))
                  }
                  color={"secondary"}
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  Create a new account
                </Link>{" "}
                or try again.
              </>
            );
            break;
          default:
            setLoginError(err);
            break;
        }
      })
      .then(() => {
        setVerifyingEmail(false);
      });
  };

  // Try to sign this user in
  const loginAction = (e = null) => {
    if (e) {
      e.preventDefault();
    }

    setLoading(true);
    setLoginError(null);

    login({ email, password })
      .then(() => {
        // Redirect
        document.location.href = afterLoginRedirectPath(true);
      })
      .catch((e) => {
        setLoginError(e);
      })
      .then(() => setLoading(false));
  };

  if (createPasswordRequired) {
    return (
      <MimicSiteContainer withHeader={withHeader}>
        <NoPassword />
      </MimicSiteContainer>
    );
  }

  if (verificationRequired) {
    return (
      <MimicSiteContainer withHeader={withHeader}>
        <EmailVerificationRequired email={email} />;
      </MimicSiteContainer>
    );
  }

  if (!emailVerified) {
    return (
      <MimicSiteContainer withHeader={withHeader}>
        <div className={style.welcomeContainer}>
          <div className={style.welcomeCard}>
            <h1 style={{ marginBottom: 20 }}>Sign in</h1>
            {/* <Typography variant="subtitle1" style={{ marginBottom: 20 }}>
              Enter your email address to sign in
            </Typography> */}

            <form onSubmit={(e) => emailVerification(e)}>
              <TextField
                variant={"outlined"}
                className={style.input}
                fullWidth
                label={"Email address"}
                placeholder={"Enter your email address"}
                style={{ marginTop: 15 }}
                disabled={verifyingEmail}
                value={email}
                type={"email"}
                helperText={loginError}
                FormHelperTextProps={{
                  className: style.error,
                }}
                onChange={(e) => setEmail(e.target.value)}
                inputProps={{
                  "data-testid": "email-field",
                  autoFocus: true,
                }}
              />

              <TextField
                className={style.input}
                type={"password"}
                label={"Hidden password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{ display: "none", visibility: "hidden", opacity: 0 }}
              />

              <Button
                variant={"contained"}
                color={"primary"}
                size={"large"}
                type={"submit"}
                disabled={verifyingEmail}
                endIcon={
                  verifyingEmail ? (
                    <CircularProgress
                      size={20}
                      thickness={5}
                      style={{ marginLeft: 10 }}
                    />
                  ) : null
                }
                data-testid={"sign-in-button"}
                fullWidth
                className={style.submitButton}
              >
                Continue
              </Button>
            </form>

            <div
              style={{
                marginTop: 20,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography>Don't have an account yet?</Typography>
              <Link
                to={"/account/intake"}
                component={BrowserLink}
                color={"secondary"}
                style={{ marginLeft: 5 }}
              >
                <Typography>Create account</Typography>
              </Link>
            </div>
          </div>
        </div>
      </MimicSiteContainer>
    );
  }

  return (
    <MimicSiteContainer withHeader={withHeader}>
      <div className={style.welcomeContainer}>
        <div className={style.welcomeCard}>
          <h1 style={{ marginBottom: 20 }}>Sign in</h1>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0 0 20px",
            }}
          >
            <Typography>
              Welcome back <b>{email}</b>!
              <Link
                onClick={() => {
                  setLoginError(null);
                  setEmailVerified(false);
                  setPassword("");
                }}
                color={"secondary"}
                style={{ marginLeft: 5, cursor: "pointer" }}
              >
                Not you?
              </Link>
            </Typography>
          </div>

          <form onSubmit={(e) => loginAction(e)}>
            <TextField
              variant={"outlined"}
              className={style.input}
              fullWidth
              type={showPassword ? "text" : "password"}
              label={"Password"}
              placeholder={"Enter your password"}
              helperText={loginError}
              FormHelperTextProps={{
                className: style.error,
              }}
              disabled={loading}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    data-testid={"toggle-password-button"}
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                ),
              }}
              inputProps={{
                "data-testid": "password-field",
                autoFocus: true,
              }}
            />

            <Link
              to={`/auth/recover?email=${encodeURIComponent(email)}`}
              component={BrowserLink}
              color={"secondary"}
              className={style.forgotPasswordLink}
            >
              Forgot password?
            </Link>

            <Button
              variant={"contained"}
              color={"primary"}
              size={"large"}
              type={"submit"}
              disabled={loading}
              endIcon={
                loading ? (
                  <CircularProgress
                    size={20}
                    thickness={5}
                    style={{ marginLeft: 10 }}
                  />
                ) : null
              }
              data-testid={"sign-in-button"}
              fullWidth
              className={style.submitButton}
            >
              Sign in
            </Button>
          </form>

          <div
            style={{
              marginTop: 20,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography>Don't have an account yet?</Typography>
            <Link
              to={"/account/intake"}
              component={BrowserLink}
              color={"secondary"}
              style={{ marginLeft: 5 }}
            >
              <Typography>Create account</Typography>
            </Link>
          </div>
        </div>
      </div>
    </MimicSiteContainer>
  );
}
