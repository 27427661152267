import React from 'react';
import { Link as BrowserLink } from 'react-router-dom';
import style from "./errors.module.scss";
import { Link } from '@mui/material';

export default function PageNotFound({ title = "Page not found", children }) {
  return (
    <div className={style.errorPage}>
      <h1>404</h1>
      <h2>{title}</h2>

      {!children && (
        <p>This page was not found. <BrowserLink to={"/"} component={Link} color={"secondary"}>Click here</BrowserLink> to return to our homepage.</p>
      )}

      {children}
    </div>
  );
}
