import { createTheme } from "@mui/material/styles";

export default createTheme({
  palette: {
    primary: {
      main: "#2D7789",
    },
    secondary: {
      main: "#ef790c",
    },
    white: {
      main: "#FFFFFF",
      contrastText: "#2D7789",
    },
    black: {
      main: "#111111",
      contrastText: "#2D7789",
    },
    gray: {
      main: "#e8e8e8",
      contrastText: "#2D7789",
    },
  },
  typography: {
    fontFamily: [
      "Averta",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "sans-serif",
    ].join(","),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 770,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },

  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "unset",
          border: "1px solid #e7e7e7",
          borderRadius: "8px",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "14px",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: "30px",
          textTransform: "unset",
          boxShadow: "unset",
        },
      },
      variants: [
        {
          props: { variant: "contained", color: "secondary" },
          style: {
            color: "#FFFFFF",
          },
        },
        {
          props: { size: "medium" },
          style: {
            padding: "8px 18px",
          },
        },
        {
          props: { size: "large" },
          style: {
            paddingLeft: 20,
            paddingRight: 20,
          },
        },
      ],
    },
  },
});
