import React, { Suspense } from "react";
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import CreateAccount from "pages/Unauthenticated/CreateAccount/CreateAccount";
import Login from "pages/Auth/Login/Login";
import PageNotFound from "pages/Errors/PageNotFound";
import Oauth from "pages/Auth/Oauth/Oauth";
import PrivateRoute from "utils/privateRoute";
import Recover from "pages/Unauthenticated/Recover/Recover";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import { useAuth } from "context/Auth";
import SignOut from "pages/Auth/Logout/SignOut";
import RecoverReset from "./Unauthenticated/RecoverReset/RecoverReset";
import SitesOverview from "pages/Sites/Overview";
import EmailVerified from "./Unauthenticated/EmailVerified/EmailVerified";
import EmailUpdated from "./Unauthenticated/EmailUpdated/EmailUpdated";
import ScrollToTopOnClick from "components/ScrollToTopOnClick/ScrollToTopOnClick";
import EditorAuth from "./Auth/EditorAuth/EditorAuth";
import OnboardSetPasswordDialog from "components/OnboardSetPasswordDialog/OnboardSetPasswordDialog";
import ExternalRedirector from "./Auth/ExternalRedirector/ExternalRedirector";
import Intake from "./Unauthenticated/Intake/Intake";
import MagicLogin from "./Unauthenticated/MagicLogin/MagicLogin";
import Loading from "components/Loading/Loading";
import "./App.scss";
import clsx from "clsx";

// Lazy load account
const Account = React.lazy(() => import("./Account/Account"));
const BetaPage = React.lazy(() => import("./Beta/Ended"));
const Create = React.lazy(() => import("./Sites/Create/Create"));
const Details = React.lazy(() =>
  import("./Subscriptions/SubscriptionDetails/Details")
);
const SiteRouter = React.lazy(() => import("./Sites/SiteRouter"));
const RecoverResetFreemius = React.lazy(() =>
  import("./Unauthenticated/RecoverReset/RecoverResetFreemius")
);
const AgencySubscription = React.lazy(() =>
  import("./AgencySubscription/AgencySubscription")
);
const AppSumoRedemption = React.lazy(() =>
  import("./Appsumo/Redemption/AppSumoRedemption")
);
const RedeemCoupon = React.lazy(() =>
  import("./Appsumo/Redemption/RedeemCoupon")
);
const AppOnboarding = React.lazy(() => import("./AppOnboarding/AppOnboarding"));
const IntegrateExternalDeveloper = React.lazy(() =>
  import(
    "./Unauthenticated/IntegrateExternalDeveloper/IntegrateExternalDeveloper"
  )
);
const AffiliateRouter = React.lazy(() =>
  import("./AffiliateDashboard/AffiliateRouter")
);

const PublicPricingPage = React.lazy(() =>
  import("./PublicPricingPage/PublicPricingPage")
);

const Support = React.lazy(() => import("./Support/Support"));

const Upgrade = React.lazy(() => import("./Upgrade/Upgrade"));
const SubscriptionConfirmed = React.lazy(() =>
  import("./Subscriptions/SubscriptionConfirmed/SubscriptionConfirmed")
);
const SubscriptionActivate = React.lazy(() =>
  import("./Subscriptions/SubscriptionActivate/SubscriptionActivate")
);

export default function App() {
  const { user, requiresPasswordReset } = useAuth();
  const hideHeader = useRouteMatch({
    path: [
      "/auth/authorize",
      "/auth/editor",
      "/apps/onboard",
      "/external",
      "/integrate",
      "/pricing",
    ],
  });

  const hideFooter = useRouteMatch({
    path: ["/account/intake", "/sites/*"],
  });

  return (
    <div
      className={clsx(
        "App",
        user &&
          document.location.pathname.substring(0, 8) !== "/pricing" &&
          "authenticated"
      )}
    >
      <ScrollToTopOnClick>
        {user && !hideHeader && <Header />}

        {user && requiresPasswordReset && <OnboardSetPasswordDialog />}

        <div className={clsx("AppContent", !hideHeader && "CanHavePadding")}>
          <Suspense fallback={<Loading text={"A moment please"} />}>
            <Switch>
              <Route path={"/"} exact>
                <Redirect to={"/overview"} />
              </Route>
              <Route path="/auth/login" component={Login} exact />
              <Route path="/auth/login/magic" component={MagicLogin} exact />
              <Route path="/account/create" component={CreateAccount} />
              <Route path="/account/email/updated" component={EmailUpdated} />
              <Route path="/account/email/verified" component={EmailVerified} />
              <Route path="/auth/recover" component={Recover} exact />
              <Route path="/pricing" component={PublicPricingPage} exact />

              <Route
                path="/auth/recover/reset"
                component={RecoverResetFreemius}
                exact
              />
              <Route
                path="/auth/recover/password/:token"
                component={RecoverReset}
                exact
              />
              <Route path="/apps/onboard" component={AppOnboarding} exact />
              <Route path="/account/intake" component={Intake} exact />

              <Route path={"/support"} component={Support} exact />

              <PrivateRoute path={"/auth/editor"} component={EditorAuth} />
              <PrivateRoute path={"/auth/logout"} component={SignOut} />
              <Route
                path="/integrate/:code"
                component={IntegrateExternalDeveloper}
                exact
              />
              <PrivateRoute
                path={"/external/:redirect/:id"}
                component={ExternalRedirector}
              />

              <PrivateRoute path={"/sites/new"} component={Create} exact />
              <PrivateRoute
                path={"/sites/onboard/:id"}
                component={Create}
                exact
              />
              <PrivateRoute path={"/sites/:id"} component={SiteRouter} />

              <PrivateRoute
                path={"/analytics/:id"}
                component={({ match }) => {
                  return (
                    <Redirect to={`/sites/${match.params.id}/analytics`} />
                  );
                }}
              />

              <PrivateRoute
                path={"/overview"}
                component={SitesOverview}
                exact
              />
              <PrivateRoute path={"/auth/authorize"} component={Oauth} exact />
              <PrivateRoute
                path={"/plan"}
                component={AgencySubscription}
                exact
              />
              <PrivateRoute
                path={"/plan/activate"}
                component={AgencySubscription}
                exact
              />
              <PrivateRoute
                path={"/subscriptions"}
                component={() => {
                  return <Redirect to={`/account/subscriptions`} />;
                }}
                exact
              />
              <PrivateRoute
                path={"/subscriptions/:id"}
                component={Details}
                exact
              />
              <PrivateRoute path={"/upgrade"} component={Upgrade} exact />

              <PrivateRoute
                path={[
                  "/subscriptions/:id/upgrade",
                  "/subscriptions/:id/update",
                ]}
                component={Upgrade}
              />
              <PrivateRoute
                path={"/order/:id"}
                component={SubscriptionConfirmed}
                exact
              />
              <PrivateRoute
                path={["/order/:id/activate", "/subscription/:id/activate"]}
                component={SubscriptionActivate}
                exact
              />

              <PrivateRoute path={"/account"} component={Account} />

              <PrivateRoute path={"/affiliate"} component={AffiliateRouter} />

              <Route path={"/beta"} component={BetaPage} exact />

              <PrivateRoute
                path={"/redeem/:code"}
                component={RedeemCoupon}
                exact
              />
              <Route path={"/appsumo"} component={AppSumoRedemption} exact />
              <Route
                path={"/appsumo/:code"}
                component={AppSumoRedemption}
                exact
              />

              <Route path="*" component={PageNotFound} />
            </Switch>
          </Suspense>
        </div>

        {!hideFooter && <Footer />}
      </ScrollToTopOnClick>
    </div>
  );
}
