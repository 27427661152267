import dlv from "dlv";
import getResource from "./getResource";

export default function getRelatedResources(
  state,
  resource,
  relation,
  _default = []
) {
  const relationData = dlv(resource, `relationships.${relation}.data`, null);

  if (!relationData) return _default;

  if (Array.isArray(relationData)) {
    const relations = [];

    relationData.map(obj => {
      const found = getResource(state, obj.type, obj.id);
      if (found) {
        relations.push(found);
      }
    });

    return relations;
  } else {
    return getResource(state, relationData.type, relationData.id);
  }
}