import React from "react";
import style from "pages/Unauthenticated/style.module.scss";
import { Button } from "@mui/material";
import OnboardingLogo from "components/Onboarding/OnboardingLogo/OnboardingLogo";

export default function EmailVerificationRequired({
  onboarding = false,
  app,
  email = "",
}) {
  return (
    <div className={style.welcomeContainer}>
      {app && <OnboardingLogo app={app} />}

      <div className={style.welcomeCard}>
        <h1>Email verification required</h1>

        <p>
          Welcome! We have sent an email to {email}. <br />
          Please verify your email address before logging in.
        </p>

        <p>
          If you did not receive the verification email, please check your junk
          folder or request a new one using the button below.
        </p>

        <p>
          If you have already verified your email, please try again in a minute
          as it may take a few seconds to sync.
        </p>

        {onboarding && (
          <Button
            variant="contained"
            style={{ marginTop: 20 }}
            onClick={() => {
              window.location.reload();
            }}
          >
            I understand
          </Button>
        )}
      </div>
    </div>
  );
}
