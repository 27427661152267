import { Button, Typography } from "@mui/material";
import Loading from "components/Loading/Loading";
import { useAuth } from "context/Auth";
import PageNotFound from "pages/Errors/PageNotFound";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import getRelatedResources from "store/helpers/getRelatedResources";
import readEndpoint from "store/helpers/readEndpoint";
import { Link as BrowserLink } from "react-router-dom";
import siteToLink from "utils/siteToLink";

export default function ExternalRedirector({ history, match: { params } }) {
  const dispatch = useDispatch();
  const { user, requiresPasswordReset } = useAuth();
  const [isLoadingData, setIsLoadingData] = useState(true);

  // Load on init
  useEffect(() => {
    // If the information hasn't been loaded yet, load it now
    dispatch(readEndpoint(`${user.id}/sites`)).then(() =>
      setIsLoadingData(false)
    );
  }, []);

  // All sites connected to this user
  const sites = useSelector((state) =>
    getRelatedResources(state, user, "sites")
  );

  if (isLoadingData) {
    return <Loading text={"Verifying"} />;
  }

  // Find site
  const site = sites.find(
    (site) => site.attributes.publicIdentifier === params.id
  );

  // Site not found in portfolio
  if (!site) {
    return (
      <PageNotFound title={"This site was not found"}>
        <Typography variant={"body1"} style={{ marginBottom: 15 }}>
          The Buttonizer instance (site) you were looking for does not exist.
        </Typography>
        <Typography variant={"body1"} style={{ marginBottom: 25 }}>
          You may need to sign in to a different account to access this site.
        </Typography>

        <Button to={"/"} component={BrowserLink} color={"secondary"}>
          Return to dashboard
        </Button>
      </PageNotFound>
    );
  }

  // Reset
  if (!requiresPasswordReset) {
    switch (params.redirect) {
      case "editor":
        document.location.href = siteToLink(site);
        break;

      case "settings":
        history.push(`/sites/${site.attributes._id}/settings`);
        break;

      case "analytics":
        history.push(`/analytics/${site.attributes._id}`);
        break;

      default:
        history.push(`/sites/${site.attributes._id}`);
        break;
    }
  }

  return (
    <Loading text={"A moment please"} subtext={"We'll redirect you soon"} />
  );
}
