export default function siteToLink(site, beta = false) {
  // WordPress website
  if (site.attributes.platform === 2) {
    return `https://${site.attributes.domain}/wp-admin/admin.php?page=Buttonizer`;
  }

  return `${process.env.REACT_APP_BUZZY_EDITOR_URL}${site.attributes._id}${
    beta ? "?betaEditor=true " : ""
  }`;
}
