import api from "utils/api";
import getResponseErrorMessage from "utils/getResponseErrorMessage";

// Api helper to patch objects, automatically updates store
export default function readEndpoint(endpoint) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      api(endpoint)
        .then(result => {
          dispatch({
            type: "API_PARSE_ENDPOINT",
            payload: result.data,
          });
          resolve(result.data);
        })
        .catch(e => reject(getResponseErrorMessage(e)));
    });
  };
}
