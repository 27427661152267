import dlv from "dlv";
import { produce } from "immer";

// Add or update an entity
function manipulateStore(draftStore, entity) {
  const type = dlv(entity, "type", false);

  // No type found
  if (!type) {
    console.error("Cannot find type of entity.", entity);
    return;
  }

  // Create store for type
  if (!draftStore[type]) {
    draftStore[type] = {};
  }

  // Save object to store
  draftStore[type][entity.id] = entity;

  return draftStore;
}

// For new resources, add relationship to existing resources
function addRelationships(draftStore, resource) {
  // Add to relationships to existing objects
  Object.keys(resource.relationships).forEach((obj) => {
    const relation = resource.relationships[obj];

    if (
      !relation.data ||
      !draftStore[relation.data.type] ||
      !draftStore[relation.data.type][relation.data.id]
    ) {
      return;
    }

    // The object has relations
    if (draftStore[relation.data.type][relation.data.id].relationships) {
      const relationShips =
        draftStore[relation.data.type][relation.data.id].relationships;

      // Loop through the relationships of entity
      Object.keys(relationShips).forEach((relKey) => {
        if (relKey === resource.type) {
          if (
            Array.isArray(relationShips[relKey].data) &&
            relationShips[relKey].data.filter(
              (externalRelation) =>
                externalRelation.type === resource.type &&
                externalRelation.id === resource.id
            ).length === 0
          ) {
            relationShips[relKey].data.push({
              type: resource.type,
              id: resource.id,
            });
          }
        }
      });
    }
  });
}

export default function apiReducer(store = {}, action) {
  return produce(store, (draftStore) => {
    switch (action.type) {
      // Add record
      case "API_CREATED":
      case "API_UPDATED":
      case "API_PARSE_ENDPOINT": {
        const payload = dlv(action, "payload", {});

        let updatedStore = draftStore;

        // Parse multiple items
        if (Array.isArray(payload.data)) {
          payload.data.forEach((obj) => manipulateStore(updatedStore, obj));
        } else {
          // Parse single item
          manipulateStore(updatedStore, payload.data);

          if (action.type === "API_CREATED" && payload.data.relationships) {
            addRelationships(updatedStore, payload.data);
          }
        }

        // Update/create included objects
        if (action.payload.included) {
          action.payload.included.forEach((obj) =>
            manipulateStore(updatedStore, obj)
          );
        }

        // Save store
        draftStore = updatedStore;

        break;
      }

      // Update specific resource attributes
      case "UPDATE_RESOURCE_ATTRIBUTES": {
        const payload = dlv(action, "payload", null);

        // No type found
        if (!payload) {
          console.error("Cannot manipulate data for", payload);
          return;
        }

        const resource = dlv(action, "payload.resource", null);

        // Update attributes
        if (
          draftStore[resource.type] &&
          draftStore[resource.type][resource.id]
        ) {
          draftStore[resource.type][resource.id].attributes = {
            ...draftStore[resource.type][resource.id].attributes,
            ...payload.attributes,
          };
        }

        break;
      }

      case "API_DELETED": {
        const payload = dlv(action, "payload", null);

        // No type found
        if (!payload || !payload.type) {
          console.error("Cannot find type of entity.", payload);
          return;
        }

        // Delete if exists
        if (draftStore[payload.type] && draftStore[payload.type][payload.id]) {
          delete draftStore[payload.type][payload.id];
        }

        // TODO: Remove relationships from relations

        break;
      }

      default:
      // Don't do anything
    }
  });
}
