import { Button, Chip, Tooltip } from "@mui/material";
import clsx from "clsx";
import React from "react";
import style from "./style.module.scss";
import { Circle, RemoveCircle } from "@mui/icons-material";

export default function SiteScreenshotOrUpgrade({ site }) {
  if (site.attributes.platform === 2) return null;

  // Plan limit reached
  if (site.attributes.reachedPlanLimit) {
    return (
      <div className={clsx(style.container, style.planLimitReached)}>
        <span>Plan limit reached</span>

        <Button variant={"contained"} color={"secondary"} size={"small"}>
          Upgrade now
        </Button>
      </div>
    );
  }

  const validationIndicator = () => {
    // Not active
    if (!site.attributes.isActive) {
      return (
        <Tooltip
          placement="top"
          disableInteractive
          title={
            site.attributes.lastActiveAt
              ? "There are no active traffic on this site anymore"
              : "We are waiting for traffic to automatically validate your site"
          }
          style={{ marginRight: 10 }}
        >
          <div>
            {site.attributes.lastActiveAt ? (
              <RemoveCircle color={"disabled"} style={{ opacity: 0.8 }} />
            ) : (
              <Circle color={"disabled"} style={{ opacity: 0.8 }} />
            )}
          </div>
        </Tooltip>
      );
    }
    return (
      <Tooltip
        placement="top"
        disableInteractive
        title={"Site is validated and has traffic"}
        style={{ marginRight: 10 }}
      >
        <div>
          <Circle color={"primary"} style={{ opacity: 0.8 }} />
        </div>
      </Tooltip>
    );
  };

  return (
    <div
      style={{
        float: "right",
        alignItems: "end",
        display: "flex",
        padding: 16,
      }}
    >
      {validationIndicator()}
      <Chip
        style={
          site.attributes.isPremium
            ? {
                backgroundColor: "#fce8d4",
                color: "#f08419",
              }
            : {
                backgroundColor: "#2d778930",
                color: "#2D7789",
              }
        }
        label={site.attributes.isPremium ? "PRO" : "Free"}
        className={style.cardChip}
      />
    </div>
  );
}
