import React, { useState } from "react";
import style from "../style.module.scss";
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { Link as BrowserLink } from "react-router-dom";
import api from "utils/api";
import getResponseErrorMessage from "utils/getResponseErrorMessage";
import { useUser } from "context/Auth";
import { useHistory } from "react-router-dom";
import MimicSiteContainer from "components/MimicSiteContainer/MimicSiteContainer";
import queryString from "query-string";
import dlv from "dlv";

export default function Recover() {
  const routeParams = queryString.parse(document.location.search);

  const user = useUser();
  const history = useHistory();
  const [isRequesting, setIsRequesting] = useState(false);
  const [forgotenType, setForgottenType] = useState("password");
  const [errorMessage, setErrorMessage] = useState(null);
  const [value, setValue] = useState(dlv(routeParams, "email", ""));
  const [resetSuccessful, setResetSuccessful] = useState(false);
  const authPath =
    window.buttonizerStartLocation &&
    window.buttonizerStartLocation.indexOf("/auth/authorize") >= 0
      ? window.buttonizerStartLocation
      : "/auth/login";

  const recover = (e = null) => {
    if (e) {
      e.preventDefault();
    }

    // Already requesting
    if (isRequesting) return;

    // Empty field
    if (value === "") {
      setErrorMessage("Field is empty");
      return;
    }

    setIsRequesting(true);

    api("/auth/recover", {
      email: value,
    })
      .then(() => setResetSuccessful(true))
      .catch((e) => setErrorMessage(getResponseErrorMessage(e)))
      .then(() => setIsRequesting(false));
  };

  // If user is logged in
  if (user !== null) {
    history.push("/overview");

    return null;
  }

  if (resetSuccessful) {
    return (
      <MimicSiteContainer>
        <div className={style.welcomeContainer}>
          <div className={style.welcomeCard}>
            <h1>It is on its way!</h1>

            <p>
              If your account was found, we'll send you an email with your
              accounts password recovery link.
            </p>

            <p>
              When you do not receive any emails soon, please check your
              SPAM-folder instead.
            </p>

            <p>Please proceed by clicking on the email we've send you.</p>
          </div>
        </div>
      </MimicSiteContainer>
    );
  }

  return (
    <MimicSiteContainer>
      <div className={style.welcomeContainer}>
        <div className={style.welcomeCard}>
          <h1>Forgot password or email?</h1>

          {errorMessage && <div className={style.error}>{errorMessage}</div>}

          <form onSubmit={recover}>
            <FormControl
              component="fieldset"
              style={{ marginTop: 20 }}
              fullWidth
              disabled={isRequesting}
            >
              <FormLabel component="legend" style={{ marginBottom: 10 }}>
                Where can we help you with?
              </FormLabel>
              <RadioGroup
                aria-label="recover"
                name="method"
                disabled={isRequesting}
                value={forgotenType}
                onChange={(e) => setForgottenType(e.target.value)}
              >
                <FormControlLabel
                  value="password"
                  control={<Radio color={"secondary"} />}
                  label="I forgot my password"
                />
                <FormControlLabel
                  value="email"
                  disabled
                  control={<Radio color={"secondary"} />}
                  label="I forgot my email (coming soon)"
                />
              </RadioGroup>
            </FormControl>

            <TextField
              variant={"outlined"}
              className={style.input}
              fullWidth
              label={"Email address"}
              placeholder={"Enter your email address"}
              style={{ marginTop: 15 }}
              disabled={isRequesting}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              error={errorMessage && value === ""}
              InputProps={{
                autoFocus: true,
              }}
            />

            <Grid
              container
              spacing={1}
              style={{ marginTop: 5 }}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Grid item xs={12} sm={6}>
                <Button
                  to={authPath}
                  component={BrowserLink}
                  color={"secondary"}
                  fullWidth
                >
                  Back to login
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  size={"large"}
                  disabled={isRequesting}
                  endIcon={
                    isRequesting ? (
                      <CircularProgress
                        size={20}
                        thickness={5}
                        style={{ marginLeft: 10 }}
                      />
                    ) : null
                  }
                  onClick={recover}
                  type={"submit"}
                >
                  Request
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </MimicSiteContainer>
  );
}
