import React, { useState } from 'react';
import style from "pages/Unauthenticated/style.module.scss";
import { Button, CircularProgress } from '@mui/material';
import { Link as BrowserLink } from 'react-router-dom';
import { logout, removeToken } from 'utils/auth';

export default function SignOut() {
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const signOutAction = () => {
    if(isLoggingOut) return;

    setIsLoggingOut(true);

    // Log out, remove token and redirect
    logout().then(() => document.location.href = '/');
  }

  return (
    <div className={style.welcomeContainer} style={{ textAlign: 'center' }}>
      <div className={style.welcomeCard}>
        <h1>Confirm logout</h1>
        <p style={{ margin: '30px 0' }}>Are you sure you want to log out?</p>

        <div style={{ marginTop: 20 }}>
          <Button color={"secondary"} component={BrowserLink} to={"/"} style={{ marginRight: 15 }}>
            No
          </Button>

          <Button
            variant={"contained"}
            color={"primary"}
            size={"large"}
            disabled={isLoggingOut}
            endIcon={isLoggingOut ? <CircularProgress size={20} thickness={5} style={{ marginLeft: 10 }} /> : null}
            onClick={signOutAction}
            >
            Logout
          </Button>
        </div>
      </div>
    </div>
  )
}
