import Loading from "components/Loading/Loading";
import React from "react";

export default function EditorAuth() {
  setTimeout(() => {
    window.close();
  }, 500);

  return <Loading text={"Please wait..."} />;
}
