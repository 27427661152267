import { Link, Paper } from "@mui/material";
import React from "react";
import style from "./style.module.scss";
import logo from "../../images/logo.svg";

export default function DashboardCrashed() {
  return (
    <div className={style.errorContainer}>
      <img src={logo} className={style.logo} />

      <Paper className={style.paper}>
        <h2>An Error Occurred 🚨</h2>
        <p>
          We're sorry! We had trouble loading this page, we have been notified
          about the error.
        </p>
        <p>
          Please try to refresh the page{" "}
          <Link href="https://app.buttonizer.io/">
            or start from the beginning
          </Link>
          .
        </p>
        <p>
          If this issue persists, please open a{" "}
          <Link
            href={"https://community.buttonizer.pro/tickets"}
            target={"_blank"}
            color={"primary"}
          >
            Support Ticket
          </Link>{" "}
          or use our{" "}
          <Link
            href={"https://buttonizer.io/contact"}
            target={"_blank"}
            color={"primary"}
          >
            contact form
          </Link>
          .
        </p>
      </Paper>
    </div>
  );
}
