import React from "react";
import clsx from "clsx";
import style from "pages/Unauthenticated/style.module.scss";
import logo from "images/icon.svg";
import { Grid } from "@mui/material";

// Onboarding logos
import ShopifyIcon from "images/fontawesome/fa-shopify";
import WordPressIcon from "images/fontawesome/fa-wordpress";
import { LinkRounded } from "@mui/icons-material";

const onboardLogos = {
  "shopify-app": (
    <ShopifyIcon
      alt={"Shopify"}
      className={clsx(style.appIcon, style.appShopify)}
    />
  ),
  wordpress: (
    <WordPressIcon
      alt={"WordPress"}
      className={clsx(style.appIcon, style.appWordPress)}
    />
  ),
};

export default function OnboardingLogo({ app, ...rest }) {
  let appIcon = null;

  // Set app icon
  if (app !== "none") {
    if (typeof onboardLogos[app] !== "undefined") {
      appIcon = onboardLogos[app];
    }
  }

  // Show Buttonizer logo
  if (!appIcon) {
    const additionalStyle = rest.style ?? {};
    return (
      <div
        className={style.logoAppConnect}
        style={{ textAlign: "center", ...additionalStyle }}
      >
        <img src={logo} alt={"Buttonizer Logo"} width={50} />
      </div>
    );
  }

  return (
    <div className={style.logoAppConnect} style={rest.style}>
      <div className={style.container}>
        <Grid container alignContent={"center"} alignItems={"center"}>
          <Grid item xs={4}>
            <img src={logo} alt={"Buttonizer Logo"} width={50} />
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <LinkRounded />
          </Grid>
          <Grid item xs={4} style={{ textAlign: "end" }}>
            {appIcon}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
