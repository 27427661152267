import React from "react";
import style from "./loading.module.scss";
import { CircularProgress, Typography } from "@mui/material";

export default function Loading({
  text = "Loading information...",
  subtext = null,
  ...rest
}) {
  return (
    <div className={style.loading} {...rest}>
      <CircularProgress size={50} />

      <Typography variant={"body1"} className={style.loadingText}>
        {text}
      </Typography>

      {subtext && <Typography variant={"body2"}>{subtext}</Typography>}
    </div>
  );
}
