export default function forceNumber(input, defaultTo = 0) {
  if (isNaN(input) || input === null) return defaultTo;

  return Number(input);
}

export function formatNumber(input) {
  const nb = forceNumber(input);

  // Fallback if feature is not available
  if (typeof nb?.toLocaleString === "undefined") return nb;

  return nb.toLocaleString();
}
