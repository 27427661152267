import clsx from "clsx";
import React from "react";
import stylesheet from "./container.module.scss";
import Notifications from "components/Notifications/Notifications";

export default function PageContainer({
  children,
  withPadding = true,
  style = null,
}) {
  return (
    <div
      className={clsx(
        stylesheet.container,
        withPadding && stylesheet.withPadding
      )}
      style={style ?? {}}
    >
      <Notifications />

      {children}
    </div>
  );
}
