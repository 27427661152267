import React, { useEffect, useState } from "react";
import Loading from "components/Loading/Loading";
import style from "pages/Unauthenticated/style.module.scss";
import { Button, CircularProgress, Grid, Link } from "@mui/material";
import { Link as BrowserLink } from "react-router-dom";
import logo from "images/logo.svg";
import { useAuth } from "context/Auth";
import api from "utils/api";
import clsx from "clsx";
import { logout } from "utils/auth";

export default function Oauth() {
  const { user } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [oauthName, setOauthName] = useState("");
  const [isAuthorizing, setIsAuthorizing] = useState(false);
  const [authParams, setAuthParams] = useState(document.location.search);
  const [isAccepting, setIsAccepting] = useState(false);
  const [error, setError] = useState(null);

  const acceptRequest = () => {
    setIsAccepting(true);

    setTimeout(() => {
      // Accept request
      api(`/oauth/authorize${authParams}`, { accepted: true }).then(
        ({ data }) => {
          document.location.href = data.uri;
        }
      );
    }, 1000);
  };

  const signOut = () => {
    if (isLoading) return;

    setIsLoading(true);

    // Log out, remove token and redirect
    logout().then(() => document.location.reload());
  };

  // Load details
  useEffect(() => {
    api(`/oauth/authorize${authParams}&show_details=true`)
      .then(({ data }) => {
        setOauthName(data.name);

        // Auto accept request
        if (data.auto_accepted) {
          setIsAccepting(true);

          setTimeout(() => {
            document.location.href = data.uri;
          }, 1000);
        }
      })
      .catch((e) => {
        if (e.response && e.response.code) {
          setError(e.response.code);
          return;
        }

        setError(e.message);
      })
      .then(() => setIsLoading(false));
  }, []);

  // Loading
  if (isLoading) {
    return <Loading text={"Almost there"} />;
  }

  // Is accepting
  if (isAccepting) {
    return <Loading text={`Logging into ${oauthName}...`} />;
  }

  // Error
  if (error) {
    return (
      <div className={style.welcomeContainer}>
        <img src={logo} className={style.logo} alt={"Buttonizer Logo"} />

        <div className={style.welcomeCard}>
          <h1>Request error</h1>
          <p>Our apologies, something went wrong. Please try again later.</p>
          <p>Error code: {error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className={style.welcomeContainer}>
      <img src={logo} className={style.logo} alt={"Buttonizer Logo"} />

      <div className={clsx(style.welcomeCard, style.welcomeCardOauth)}>
        <h1>{oauthName}</h1>
        <p>
          Hi {user.attributes.firstName}. You're about to authorize the
          application <i>{oauthName}</i> to sign you in with your Buttonizer
          account. Do you want to proceed?
        </p>

        <Grid
          container
          spacing={1}
          style={{ marginTop: 20 }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Grid item xs={12} sm={6}>
            <Button
              color={"secondary"}
              fullWidth
              onClick={() => window.close()}
            >
              Deny request
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant={"contained"}
              color={"primary"}
              size={"large"}
              disabled={isAuthorizing}
              onClick={acceptRequest}
            >
              Authorize
            </Button>
          </Grid>
        </Grid>

        <div className={style.oauthBottomLink}>
          Not your account?{" "}
          <Link onClick={signOut}>Use a different account</Link>
        </div>

        <div className={style.oauthBottomLink}>
          <Button
            component={Link}
            href={"https://community.buttonizer.pro/knowledgebase/823"}
            target={"_blank"}
          >
            What is this?
          </Button>
        </div>
      </div>
    </div>
  );
}
