import React, { useState, Fragment, useEffect } from "react";
import { getAuthentication } from "../utils/auth";
import { useSnackbar } from "notistack";
import { Button, Backdrop, CircularProgress } from "@mui/material";
// import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import readEndpoint from "../store/helpers/readEndpoint";
import getResource from "../store/helpers/getResource";
import getRelatedResources from "store/helpers/getRelatedResources";

const AuthContext = React.createContext();

// AuthProvider
const AuthProvider = (props) => {
  const [userId, setUserId] = useState(props.userId);
  const [timezone, setTimezone] = useState(null);
  const [lastDestroyTime, setLastDestroyTime] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isRejected, setIsRejected] = useState(false);
  const [hasBetaSignUp, setHasBetaSignup] = useState(false);
  const [hasBetaAccess, setHasBetaAccess] = useState(false);
  const [canImpersonate, setCanImpersonate] = useState(false);
  const [isImpersonating, setIsImpersonating] = useState(false);
  const [requiresPasswordReset, setRequiresPasswordReset] = useState(false);
  const [isOverOneMonthOld, setIsOverOneMonthOld] = useState(false);
  const [currentSiteId, setCurrentSiteId] = useState(null);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const user = useSelector((state) => getResource(state, "users", userId));
  const userPrimaryLicense = useSelector((state) =>
    getRelatedResources(state, user, "primaryLicense", null)
  );

  useEffect(() => {
    if (user) {
      setIsAuthenticating(false);
      return;
    }

    // Get the user data
    getAuthentication()
      .then(({ data }) => {
        setUserId(data.id);

        // Update timezone
        // moment.tz.setDefault(data.timezone);
        setTimezone(data.timezone);

        setHasBetaSignup(data.hasBetaSignedUp);
        setHasBetaAccess(data.hasBetaAccess);
        setRequiresPasswordReset(data.requiresPasswordReset);
        setIsOverOneMonthOld(data.isOverOneMonthOld);

        // Support tools
        if (data.tools) {
          setCanImpersonate(data.tools.can_impersonate);
          setIsImpersonating(data.tools.is_switched);
        }

        dispatch(readEndpoint(data.id))
          .then(({ data }) => {
            setIsAuthenticating(false);

            // Prefill user data
            if (window.tidioChatApi) {
              window.tidioChatApi.setVisitorData({
                name: data.attributes.firstName,
                email: data.attributes.email,
              });
            }

            // Set Buttonizer parameters
            // Buttonizer is loaded
            if (typeof window.Buttonizer !== "undefined") {
              window.Buttonizer.setParameter(
                "first_name",
                data.attributes.firstName
              );
              window.Buttonizer.setParameter("email", data.attributes.email);
            }
            // Buttonizer is not yet loaded
            else {
              if (!window._buttonizer) {
                window._buttonizer = {};
              }

              var _custom_page_data = {
                first_name: data.attributes.firstName,
                email: data.attributes.email,
              };

              // Merges page data with other potential data
              window._buttonizer.data = {
                ..._custom_page_data,
                ...window._buttonizer.data,
              };
            }
          })
          .catch(() => setIsRejected(true));
      })
      .catch((err) => {
        setIsAuthenticating(false);

        // Token was rejected by the server
        if (err.response !== undefined && err.response.status === 401) {
          setIsRejected(true);
          enqueueSnackbar("Your session has expired.", {
            autoHideDuration: 5000,
            action: (key) => (
              <Fragment>
                <Button
                  color="primary"
                  size="small"
                  onClick={() => closeSnackbar(key)}
                >
                  Ok
                </Button>
              </Fragment>
            ),
          });
        }
      });
  }, []);

  if (
    isAuthenticating &&
    (document.location.pathname.substring(0, 8) !== "/pricing" || isRejected)
  ) {
    if (isRejected) {
      return (
        <div style={{ color: "red" }}>
          <p>Uh oh... There's a problem. Try refreshing the app.</p>
        </div>
      );
    }

    return (
      <Backdrop open={true} style={{ background: "white" }}>
        <div
          style={{
            color: "#black",
            fontSize: 15,
            textAlign: "center",
          }}
        >
          <CircularProgress color="primary" size={70} />
          <p
            style={{
              marginTop: 30,
              display: "block",
            }}
          >
            Loading account information
          </p>
        </div>
      </Backdrop>
    );
  }

  // Store actions
  const actions = {
    updateUserData: (updatedData) => {},
    destroyUser: () => {
      setLastDestroyTime(Date.now());
      setUserId(null);
    },
    updateTimezone: (timezone) => {
      // Update timezone
      //   moment.tz.setDefault(timezone);

      setTimezone(timezone);
    },
    setHasBetaSignup: (value) => setHasBetaSignup(value),
    setRequiresPasswordReset: () => setRequiresPasswordReset(false),
    setCurrentSiteId,
  };

  // Store data
  const data = {
    user,
    userId,
    isAgency: user && user.attributes.isAgency,
    isAppSumoCustomer: user && user.attributes.isAppSumoCustomer,
    hasBouncedEmail: user && user.attributes.emailBounced,
    userPrimaryLicense,
    isAuthenticated: userId !== null,
    lastDestroyTime,
    timezone,
    hasBetaSignUp,
    hasBetaAccess,
    requiresPasswordReset,
    canImpersonate,
    isImpersonating,
    isOverOneMonthOld,
    currentSiteId,
  };

  return (
    <AuthContext.Provider
      value={{
        ...data,
        ...actions,
      }}
      {...props}
    />
  );
};

// useAuth
const useAuth = () => {
  const context = React.useContext(AuthContext);

  // Using outside context
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }

  return context;
};

// Use user
const useUser = () => {
  const context = React.useContext(AuthContext);

  // Using outside context
  if (context === undefined) {
    throw new Error(`useUser must be used within a AuthProvider`);
  }

  return context.user;
};

export { AuthProvider, useAuth, useUser };
