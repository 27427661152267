import React, { useState } from "react";
import style from "./infoCard.module.scss";
import { Card, CardContent, IconButton, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import getCookie from "utils/getCookie";
import setCookie from "utils/setCookie";

export default function InfoCard({
  children,
  dismissable = false,
  dismissableId = "",
  showAfterDays = 15,
  ...rest
}) {
  const [isHidden, setIsHidden] = useState(false);

  if (
    dismissable &&
    dismissableId &&
    (getCookie(`dismissable_${dismissableId}`) || isHidden)
  )
    return null;

  return (
    <Card className={style.infoCard} {...rest}>
      <CardContent>
        {dismissable && dismissableId && (
          <Tooltip title={"Dismiss"}>
            <IconButton
              onClick={() => {
                setCookie(`dismissable_${dismissableId}`, true, showAfterDays);
                setIsHidden(true);
              }}
              className={style.dismissButton}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        )}
        {children}
      </CardContent>
    </Card>
  );
}
