import React from "react";
import style from "./header.module.scss";
import { Link } from "react-router-dom";
import { useUser, useAuth } from "context/Auth";
import BuzzyImage from "images/icon-head-only.svg";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import clsx from "clsx";
import SitePlanCircular from "components/SitePlanCircular/SitePlanCircular";
import BlackFridayBar from "./Bars/BlackFridayBar";
import ChangelogButton from "./ChangelogButton/ChangelogButton";
import AccountMenu from "./AccountMenu/AccountMenu";
import SiteMenuItems from "./SiteMenuItems/SiteMenuItems";

export default function Header({ noPadding = false, onPublicPricing = false }) {
  const user = useUser();
  const auth = useAuth();

  const showUserItems = !onPublicPricing && user;

  return (
    <>
      <BlackFridayBar />

      <header
        className={clsx(
          style.header,
          noPadding && style.noPadding,
          showUserItems ? style.authenticated : style.unauthenticated,
          onPublicPricing && style.onPublicPricing
        )}
      >
        <div
          className={clsx(
            style.container,
            !showUserItems && style.unauthenticated
          )}
        >
          {!showUserItems && (
            <div className={style.logo}>
              <Link to={"/"}>
                <img src={BuzzyImage} alt={"Buttonizer"} />
              </Link>
            </div>
          )}
          {!showUserItems && (
            <div className={style.menu}>
              <ul>
                <li>
                  <a href={"https://buttonizer.io/features/"} rel="noreferrer">
                    Features
                  </a>
                </li>
                <li>
                  <a href={"https://buttonizer.io/platforms/"} rel="noreferrer">
                    Integrations
                  </a>
                </li>
                <li>
                  <Link to={"/pricing/"}>Pricing</Link>
                </li>
                <li>
                  <a href={"https://buttonizer.io/contact/"} rel="noreferrer">
                    Need help?
                  </a>
                </li>
                <li>
                  <a href={"https://buttonizer.io/blog/"} rel="noreferrer">
                    Blog
                  </a>
                </li>
              </ul>
            </div>
          )}

          {showUserItems && <SiteMenuItems />}

          {auth.isAgency && showUserItems && (
            <SitePlanCircular
              visitCounter={user.attributes.agencyPlanVisitorCount}
              planVisitLimit={user.attributes.agencyPlanVisitorCountLimit}
            />
          )}

          <div className={style.headerActions}>
            {user && (
              <>
                <ChangelogButton />

                <AccountMenu />
              </>
            )}
            {!user && (
              <>
                <Link
                  to={"/auth/login"}
                  className={clsx(style.secondaryActionButton)}
                >
                  Sign in
                </Link>
                <Link
                  to={"/account/intake"}
                  className={clsx(style.actionButton)}
                >
                  <AccountCircleIcon className={style.newAccountIcon} />
                  Create account
                </Link>
              </>
            )}
          </div>
        </div>
      </header>
    </>
  );
}
