import React from "react";
import style from "pages/Unauthenticated/style.module.scss";
import OnboardingLogo from "components/Onboarding/OnboardingLogo/OnboardingLogo";
import { Button } from "@mui/material";

export default function NoPassword({ onboarding = false, app, email = "" }) {
  return (
    <div className={style.welcomeContainer}>
      {app && <OnboardingLogo app={app} />}

      <div className={style.welcomeCard}>
        <h1>Please create a password</h1>

        <p>Welcome back! Your account has no password configured yet.</p>

        <p>
          Because of this, we have sent you a password reset email to set a
          password.
        </p>

        <p>Please check your inbox.</p>

        {onboarding && (
          <Button
            variant="contained"
            style={{ marginTop: 20 }}
            onClick={() => {
              window.location.reload();
            }}
          >
            I understand
          </Button>
        )}
      </div>
    </div>
  );
}
