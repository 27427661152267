import React from "react";
import ReactDOM from "react-dom";
import App from "./pages/App";
import * as Sentry from "@sentry/react";
import ApplicationContainer from "./ApplicationContainer";
import queryString from "query-string";
import "./index.scss";

// Password reset
if (window.location.hash && window.location.hash.indexOf("!") >= 0) {
  const path = window.location.hash
    .substr(window.location.hash.indexOf("!") + 1)
    .replace(/^\/+|\/+$/g, "");

  // Freemius Password Reset URL
  if (path.indexOf("password/reset") >= 0) {
    const redirectUri = decodeURIComponent(
      path.replace("password/reset/?reset_token=", "")
    ).replace("password/reset", "auth/recover/reset");

    document.location.href = redirectUri;
  }
}

// Remember start location
if (document.location.pathname) {
  window.buttonizerStartLocation =
    document.location.pathname + document.location.search;
}

// Remember the start search query
window.startSearchQuery = document.location.search;

// Resize window if possible
if (window.location.href.indexOf("/auth/authorize") >= 0) {
  window.resizeTo(455, 650);
}

// Get coupon (if set)
const currentSearchQuery = queryString.parse(document.location.search);

// BlackFriday 50% discount
if (process.env.REACT_APP_BLACK_FRIDAY === "true") {
  window.buttonizerUpgradeCoupon = process.env.REACT_APP_BLACK_FRIDAY_COUPON;
}

// Special discount coupon
if (process.env.REACT_APP_DISCOUNT === "true") {
  window.buttonizerUpgradeCoupon = process.env.REACT_APP_DISCOUNT_COUPON;
}

// Set coupon
if (currentSearchQuery.coupon) {
  window.buttonizerUpgradeCoupon = currentSearchQuery.coupon;
}

// Agency Plan ID
if (currentSearchQuery.agencyPlanId) {
  window.buttonizerUpgradePlanId = currentSearchQuery.agencyPlanId;
  window.buttonizerUpgradePlanCycle = "annual";
}

// Upgrade to specific plan
if (currentSearchQuery.planId) {
  window.buttonizerUpgradePlanId = currentSearchQuery.planId;
  window.buttonizerUpgradePlanCycle =
    currentSearchQuery.cycle === "annual" ? "annual" : "monthly";
}

// Add classname to body
document.body.className = "app--ready";

// Initialize Sentry
// Only enable on production mode
if (process.env.REACT_APP_DEBUG === "false") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [],
    release: process.env.REACT_APP_VERSION,
    environment:
      process.env.REACT_APP_DEBUG === "true" ? "development" : "production",
    tracesSampleRate: 0,
  });
}

ReactDOM.render(
  <ApplicationContainer>
    <App />
  </ApplicationContainer>,
  document.getElementById("root")
);
