import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import api from "./reducers/apiReducer";

const reducer = combineReducers({
  api,
});

const store = createStore(reducer, applyMiddleware(thunk));

export default store;
