import { useAuth, useUser } from "context/Auth";
import React from "react";
import style from "./style.module.scss";
import { Alert, AlertTitle, Button, Divider, Typography } from "@mui/material";
import { Link as BrowserLink } from "react-router-dom";
import InfoCard from "components/InfoCard/InfoCard";

export default function Notifications() {
  const user = useUser();
  const auth = useAuth();

  return (
    <>
      {auth.hasBouncedEmail && (
        <div className={style.notificationContainer}>
          <div className={style.container}>
            <Alert severity="warning" style={{ width: "100%" }}>
              <AlertTitle>Your email bounced</AlertTitle>
              <Typography>
                It seems that an email from us has bounced and could not be
                delivered to your email address. Please update your email
                address.
              </Typography>
              <div className={style.notificationAction}>
                <Button
                  color={"secondary"}
                  component={BrowserLink}
                  to={"/account"}
                >
                  Update email
                </Button>
              </div>
            </Alert>
          </div>
        </div>
      )}

      {/* New: Monthly analytics report */}
      {user &&
        user?.attributes?.emailPreferences &&
        user?.attributes?.emailPreferences?.indexOf("monthly_reports") ===
          -1 && (
          <div className={style.notificationContainer}>
            <div className={style.container}>
              <InfoCard
                dismissable
                dismissableId="beta_monthly_mail"
                showAfterDays={30}
              >
                <Typography variant="body1">
                  <b>Exciting news:</b> We have finished working on Monthly
                  Reports. You can join the BETA via your notification settings,
                  enable '<i>Monthly conversion reports</i>' to participate.
                </Typography>
                <Button
                  component={BrowserLink}
                  to={"/account/notifications"}
                  variant="outlined"
                >
                  Email notification settings
                </Button>
              </InfoCard>
            </div>
          </div>
        )}

      {/* AppSumo user */}
      {user &&
        user?.attributes?.emailPreferences &&
        user?.attributes?.emailPreferences?.indexOf("monthly_reports") >= 0 &&
        auth.isAppSumoCustomer &&
        auth.isOverOneMonthOld && (
          <div className={style.notificationContainer}>
            <div className={style.container}>
              <InfoCard
                dismissable
                dismissableId="appsumo_review_us"
                style={{ width: "100%" }}
                showAfterDays={365}
              >
                <Typography variant="body1">
                  <b>Hey Summoling!</b> We hope you're enjoying Buttonizer!
                </Typography>
                <Typography variant="body1">
                  If you do, make sure to leave a review and 🌮🌮🌮🌮🌮 tacos on
                  AppSumo :)
                </Typography>
                <Button
                  href={"https://r.buttonizer.io/review/appsumo"}
                  target={"_blank"}
                  variant="outlined"
                  startIcon={"🌮"}
                >
                  Review us on AppSumo
                </Button>

                <Divider
                  sx={{
                    width: `55px`,
                    marginLeft: `15px`,
                    marginRight: `15px`,
                    verticalAlign: `middle`,
                    display: "inline-flex",
                  }}
                >
                  or
                </Divider>

                <Button
                  href={"https://r.buttonizer.io/feedback"}
                  target={"_blank"}
                  variant={"text"}
                >
                  give feedback
                </Button>
              </InfoCard>
            </div>
          </div>
        )}
    </>
  );
}
