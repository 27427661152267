import { ThemeProvider } from "@mui/material/styles";
import { AuthProvider } from "./context/Auth";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import React from "react";
import theme from "./theme";
import store from "./store/index.js";
import "./index.scss";
import DashboardCrashed from "components/DashboardCrashed/DashboardCrashed";

export default function ApplicationContainer({
  children,
  storeProvider = store,
  userId = null,
}) {
  return (
    <React.StrictMode>
      <Sentry.ErrorBoundary fallback={<DashboardCrashed />} showDialog>
        <ThemeProvider theme={theme}>
          <Provider store={storeProvider}>
            <SnackbarProvider maxSnack={3}>
              <AuthProvider userId={userId}>
                <BrowserRouter>{children}</BrowserRouter>
              </AuthProvider>
            </SnackbarProvider>
          </Provider>
        </ThemeProvider>
      </Sentry.ErrorBoundary>
    </React.StrictMode>
  );
}
