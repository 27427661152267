export default function getResponseErrorMessage(err) {
  if (err.response && err.response.data && err.response.data.message) {
    return err.response.data.message;
  } else if (err.response && err.response.data && err.response.data.detail) {
    return err.response.data.detail;
  } else if (err.response && err.response.detail) {
    return err.response.detail;
  } else if (err.message) {
    return err.message;
  }

  return err;
}
