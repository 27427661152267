import React from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CoffeeIcon from "@mui/icons-material/Coffee";
import versionNumber, { builtTime } from "utils/versionNumber";
import DutchFlag from "../../images/dutch-flag.svg";
import style from "./footer.module.scss";
import { Grid, Link, Tooltip, Typography } from "@mui/material";
import { useUser } from "context/Auth";
import { Link as BrowserLink } from "react-router-dom";

const currentYear = new Date().getFullYear();

export default function Footer() {
  const user = useUser();

  return (
    <footer className={style.footer}>
      <div className={style.container}>
        {!user && (
          <Grid container spacing={2}>
            <Grid xs={12} md={4} item>
              <Typography variant={"body1"} fontWeight={800} gutterBottom>
                Company
              </Typography>
              <Link
                href={"https://www.buttonizer.io/about/"}
                className={style.link}
                target={"_blank"}
              >
                About us
              </Link>
              <Link
                href={"https://www.buttonizer.io/blog/"}
                className={style.link}
                target={"_blank"}
              >
                Blog
              </Link>
              <Link
                to={"/pricing/"}
                className={style.link}
                component={BrowserLink}
              >
                Pricing
              </Link>
              {/* <Link
                href={"https://buttonizer.io/affiliates/"}
                className={style.link}
                target={"_blank"}
              >
                Affiliate program
              </Link> */}
              <Link
                href={"https://www.buttonizer.io/contact/"}
                className={style.link}
                target={"_blank"}
              >
                Contact
              </Link>
            </Grid>
            <Grid xs={12} md={4} item>
              <Typography variant={"body1"} fontWeight={800} gutterBottom>
                Resources
              </Typography>

              <Link
                href={"https://r.buttonizer.io/support/knowledgebase"}
                className={style.link}
                target={"_blank"}
              >
                Knowledge Base
              </Link>
              <Link
                href={"https://r.buttonizer.io/support/community"}
                className={style.link}
                target={"_blank"}
              >
                Buttonizer Community
              </Link>
              <Link
                href={"https://r.buttonizer.io/socials/youtube"}
                className={style.link}
                target={"_blank"}
              >
                Video tutorials
              </Link>
              <Link
                href={"https://r.buttonizer.io/changelog"}
                className={style.link}
                target={"_blank"}
              >
                Changelog
              </Link>
              <Link
                href={"https://r.buttonizer.io/roadmap"}
                className={style.link}
                target={"_blank"}
              >
                Roadmap
              </Link>
            </Grid>
            <Grid xs={12} md={4} item>
              <Typography variant={"body1"} fontWeight={800} gutterBottom>
                Legal
              </Typography>

              <Link
                href={"https://www.buttonizer.io/gdpr/"}
                className={style.link}
                target={"_blank"}
              >
                GDPR Compliance
              </Link>
              <Link
                href={"https://www.buttonizer.io/privacy-policy/"}
                className={style.link}
                target={"_blank"}
              >
                Privacy Policy
              </Link>
              <Link
                href={"https://www.buttonizer.io/terms-conditions/"}
                className={style.link}
                target={"_blank"}
              >
                Terms of Service
              </Link>
              <Link
                href={"https://www.buttonizer.io/security/"}
                className={style.link}
                target={"_blank"}
              >
                Security &amp; disclosure
              </Link>
              <Link
                href={"https://www.buttonizer.io/trademark/"}
                className={style.link}
                target={"_blank"}
              >
                Trademark
              </Link>
            </Grid>
          </Grid>
        )}

        <Grid container spacing={2} sx={{ marginTop: "20px" }}>
          <Grid xs={12} md={6} item>
            &copy; 2017 - {currentYear} Buttonizer &reg;
          </Grid>
          <Grid xs={12} md={6} item sx={{ textAlign: "right" }}>
            {user && (
              <p className={style.info}>
                <a
                  href={"https://r.buttonizer.io/status"}
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  Status
                </a>{" "}
                |{" "}
                <a
                  href={"https://buttonizer.io/security/"}
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  Security
                </a>{" "}
                |{" "}
                <a
                  href={"https://buttonizer.io/gdpr/"}
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  GDPR
                </a>{" "}
                |{" "}
                <a
                  href={"https://buttonizer.io/privacy-policy/"}
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  Privacy Policy
                </a>{" "}
                |{" "}
                <a
                  href="https://buttonizer.io/terms-conditions/"
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  Terms of Service
                </a>
              </p>
            )}
          </Grid>
        </Grid>
      </div>

      <div className={style.subfooterContainer}>
        <div className={style.subfooter}>
          Built with{" "}
          <Tooltip title={"much love"}>
            <FavoriteIcon className={style.loveIcon} />
          </Tooltip>{" "}
          and{" "}
          <Tooltip title={"lots and lots of coffee"}>
            <CoffeeIcon className={style.coffeeIcon} />
          </Tooltip>{" "}
          in The Netherlands{" "}
          <Tooltip title={"Buttonizer HQ in Zoetermeer"}>
            <img src={DutchFlag} className={style.dutchFlag} />
          </Tooltip>
        </div>
        <div className={style.subfooter}>
          <Tooltip
            title={`This dashboard was last deployed on: ${builtTime()}`}
          >
            <p className={style.info}>Build number {versionNumber()}</p>
          </Tooltip>
        </div>
      </div>
    </footer>
  );
}
