import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import RedeemTwoToneIcon from "@mui/icons-material/RedeemTwoTone";
import NewspaperIcon from "@mui/icons-material/Newspaper";

export default function ChangelogButton() {
  return (
    <Tooltip title={"Read about the latest changes in our changelog"}>
      <IconButton
        href={
          "https://r.buttonizer.io/changelog?utm_source=dashboard&utm_medium=header"
        }
        target={"_blank"}
      >
        <NewspaperIcon color="primary" />
      </IconButton>
    </Tooltip>
  );
}
