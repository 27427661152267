import React from "react";
import { useUser } from "../context/Auth";
import { Route, Redirect } from "react-router-dom";

export default function PrivateRoute({ ...rest }) {
  const user = useUser();

  // When user is logged in
  if (!user ) {
    return (
      <Route
        path={rest.path}
        render={({ location }) => {
          return (
            <Redirect
              to={`/auth/login?from=${encodeURIComponent(location.pathname + location.search)}`}
            />
          );
        }}
      />
    );
  }

  return <Route {...rest} />;
}
