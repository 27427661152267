import isURL from "validator/lib/isURL";

export function domainIsValid(value) {
  // Enable localhost domain
  if (value === "localhost") {
    return true;
  }

  return isURL(value, {
    protocols: [],
    require_tld: true,
    require_host: true,
    require_protocol: false,
    allow_fragments: false,
    allow_query_components: false,
    allow_protocol_relative_urls: false,
    disallow_auth: true,
  });
}

export function getDomainFromValue(val) {
  let domain = val.replace(/http(s?):\/\//, "");
  domain = domain.split("/");

  return domain[0];
}
