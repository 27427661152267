import Axios from "axios";
import { getToken, setToken, removeToken } from "./auth.js";

export default function api(
  endpoint,
  body,
  customData = {},
  headers = {},
  retry = true
) {
  return new Promise((resolve, reject) => {
    startRequest(endpoint, body, customData, headers)
      .then((data) => resolve(data))
      // Error
      .catch((error) => {
        if (!retry || (error.response && error.response.status !== 401)) {
          reject(error);
          return;
        }

        // Remove token
        delete Axios.defaults.headers.common["Authorization"];

        // Try to update token
        updateRefreshTokenInterceptor()
          .then((refreshTokenData) => {
            setToken(refreshTokenData.data.token);

            resolve(startRequest(endpoint, body, customData, headers));
          })
          .catch((refreshTokenError) => {
            reject(refreshTokenError);

            // Redirect to login page
            // Reason: Session expired
            if (error.response && error.response.status === 401) {
              removeToken();

              document.location.href = `/auth/login?from=${encodeURIComponent(
                document.location.pathname + document.location.search
              )}`;
            }
          });
      });
  });
}

function startRequest(endpoint, body, customData = {}, headers = {}) {
  return Axios({
    url: `${process.env.REACT_APP_API_URL}${endpoint}`,
    method: body ? "POST" : "GET",
    data: body,
    withCredentials: true,
    headers: {
      ...headers,
    },
    ...customData,
  });
}

function updateRefreshTokenInterceptor() {
  return Axios({
    url: `${process.env.REACT_APP_API_URL}/auth/refresh`,
    method: "POST",
    withCredentials: true,
    data: {
      token: getToken(),
    },
  });
}
