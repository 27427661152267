import React from "react";
import style from "../style.module.scss";
import { Button } from "@mui/material";
import { Link as BrowserLink } from "react-router-dom";
import { useUser } from "context/Auth";
import MimicSiteContainer from "components/MimicSiteContainer/MimicSiteContainer";

export default function EmailUpdated() {
  const user = useUser();

  const content = (
    <div className={style.welcomeContainer}>
      <div className={style.welcomeCard}>
        <h1>Email updated!</h1>
        <p>
          Thank you for verifying your email. Your email address is now updated.
        </p>

        <p>
          If you run into any trouble or have questions, please let us know :)
        </p>

        <Button to={"/"} component={BrowserLink} color={"secondary"} fullWidth>
          Continue
        </Button>
      </div>
    </div>
  );

  // If logged in, show content without header
  if (user) {
    return content;
  }

  return <MimicSiteContainer>{content}</MimicSiteContainer>;
}
