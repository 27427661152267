import { useAuth, useUser } from "context/Auth";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import getRelatedResources from "store/helpers/getRelatedResources";
import readEndpoint from "store/helpers/readEndpoint";
import Loading from "components/Loading/Loading";
import PageContainer from "components/PageContainer/PageContainer";
import {
  Typography,
  Button,
  Card,
  TextField,
  IconButton,
  CardActionArea,
  CardContent,
  Grid,
  Tooltip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FontAwesomeWordPress from "images/fontawesome/fa-wordpress.svg";
import FontAwesomeShopify from "images/fontawesome/fa-shopify.svg";
import SearchIcon from "@mui/icons-material/Search";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import { Link as BrowserLink } from "react-router-dom";
import style from "./overview.module.scss";
import siteToLink from "utils/siteToLink";
import dlv from "dlv";
import SitePlanBar from "components/SitePlanBar/SitePlanBar";
import SettingsIcon from "@mui/icons-material/Settings";
import SiteScreenshotOrUpgrade from "components/SiteScreenshotOrUpgrade/SiteScreenshotOrUpgrade";
import getRelationship from "store/helpers/getRelationship";

let searchTimeout = setTimeout(() => {}, 1);

export default function Overview() {
  const dispatch = useDispatch();
  const user = useUser();
  const { hasBetaAccess } = useAuth();

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [filterKeyword, setFilterKeyword] = useState("");
  const [licenseType, setLicenseType] = useState("all");
  const [nextUrl, setNextUrl] = useState(null);
  const [isLoadingNextData, setIsLoadingNextData] = useState(false);

  const [isSearching, setIsSearching] = useState(false);
  const [searchingTerm, setSearchingTerm] = useState("");

  const userSites = getRelationship(user, "sites", []);

  const [platformFilter, setPlatformFilter] = useState("all");

  // Load on init
  useEffect(() => {
    // If the information hasn't been loaded yet, load it now
    dispatch(readEndpoint(`${user.id}/sites`)).then(({ links }) => {
      setIsLoadingData(false);

      setNextUrl(links?.next);
    });
  }, []);

  // All sites connected to this user
  const sites = useSelector((state) =>
    getRelatedResources(state, user, "sites")
  );

  const filteredSiteList = sites
    .filter((obj) =>
      filterKeyword === ""
        ? true
        : dlv(obj, "attributes.title", "Untitled site").indexOf(
            filterKeyword
          ) >= 0 ||
          dlv(obj, "attributes.domain", "error")
            .toLowerCase()
            .indexOf(filterKeyword) >= 0
    )
    .filter((site) => {
      if (licenseType === "all") {
        return site;
      }

      if (licenseType === "pro" && site.attributes.isPremium) {
        return site;
      }

      if (licenseType === "free" && site.attributes.isPremium === false) {
        return site;
      }
    })
    .filter((site) => {
      switch (platformFilter) {
        case "standalone":
          if (site.attributes.platform === 0 || site.attributes.platform === 1)
            return site;
          break;
        case "shopify":
          if (site.attributes.platform === 3) return site;
          break;
        case "wordpress":
          if (site.attributes.platform === 2 || site.attributes.platform === 4)
            return site;
          break;
        case "all":
        default:
          return site;
      }
    });

  // Loading data
  if (isLoadingData) {
    return (
      <Loading
        text={"Loading your websites... (Please refresh if it takes long)"}
      />
    );
  }

  // Find next page
  const nextPage = () => {
    setIsLoadingNextData(true);

    dispatch(readEndpoint(nextUrl)).then(({ links }) => {
      setIsLoadingNextData(false);

      setNextUrl(links?.next);
    });
  };

  // Load more items
  const loadMoreSitesByKeyword = (keyword) => {
    if (
      keyword !== "" &&
      filteredSiteList.length < 3 &&
      searchingTerm !== keyword &&
      keyword.length >= 3
    ) {
      clearTimeout(searchTimeout);
      setSearchingTerm(keyword);
      setIsSearching(true);

      searchTimeout = setTimeout(() => {
        dispatch(
          readEndpoint(
            `${user.id}/sites?title=${encodeURIComponent(
              keyword
            )}&domain=${encodeURIComponent(keyword)}`
          )
        ).then(() => setIsSearching(false));
      }, 1000);
    }
  };

  const licenseTypeAmount = () => {
    if (licenseType !== "all" || platformFilter !== "all") {
      return filterKeyword === "" ? (
        <>
          There are <b>{filteredSiteList.length}</b> results
        </>
      ) : (
        <>
          There are <b>{filteredSiteList.length}</b> results found for{" "}
          <b>{filterKeyword}</b>. {isSearching && "(Searching)"}
        </>
      );
    }

    return filterKeyword === "" ? (
      <>
        In total you have <b>{userSites.length} </b> sites connected to your
        account.
      </>
    ) : (
      <>
        There are <b>{filteredSiteList.length}</b> results for{" "}
        <b>{filterKeyword}</b>. {isSearching && "(Searching)"}
      </>
    );
  };

  return (
    <PageContainer style={{ maxWidth: 750 }}>
      <div className={style.sitesHeader}>
        <Typography variant={"h4"}>
          Welcome back, {user.attributes.firstName}
        </Typography>
        <div className={style.sitesHeaderButton}>
          <Tooltip title={""}>
            <span>
              <Button
                variant={"contained"}
                color={"primary"}
                size={"large"}
                startIcon={<AddIcon />}
                component={BrowserLink}
                to={"/sites/new"}
              >
                New site
              </Button>
            </span>
          </Tooltip>
        </div>
      </div>

      <Grid container spacing={2} className={style.inputContainer}>
        {sites.length > 0 && (
          <>
            <Grid item xs={7}>
              <TextField
                variant={"outlined"}
                className={style.input}
                fullWidth
                label={"Search website"}
                placeholder={"Enter search keyword"}
                value={filterKeyword}
                onChange={(e) => {
                  setFilterKeyword(e.target.value.toLowerCase());
                  loadMoreSitesByKeyword(e.target.value.toLowerCase());
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      size="large"
                    >
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
                helperText={licenseTypeAmount()}
              />
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel>Plan filter</InputLabel>
                <Select
                  value={licenseType}
                  onChange={(e) => {
                    setLicenseType(e.target.value);
                  }}
                  label="Plan filter"
                >
                  <MenuItem value="all">
                    <em>All Plans</em>
                  </MenuItem>
                  <MenuItem value={"free"}>Free</MenuItem>
                  <MenuItem value={"pro"}>Pro</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel>Platform filter</InputLabel>
                <Select
                  value={platformFilter}
                  onChange={(e) => {
                    setPlatformFilter(e.target.value);
                  }}
                  label="Platform filter"
                >
                  <MenuItem value="all">
                    <em>All platforms</em>
                  </MenuItem>
                  <MenuItem value={"standalone"}>Standalone</MenuItem>
                  <MenuItem value={"wordpress"}>WordPress</MenuItem>
                  <MenuItem value={"shopify"}>Shopify</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </>
        )}
      </Grid>
      {sites.length === 0 && (
        <div style={{ textAlign: "center", margin: "50px 0" }}>
          <Typography variant={"h6"} style={{ marginBottom: 30 }}>
            You have not added any websites yet.
          </Typography>

          <span>
            <Button
              variant={"contained"}
              color={"primary"}
              size={"large"}
              startIcon={<AddIcon />}
              component={BrowserLink}
              to={"/sites/new"}
            >
              Add
            </Button>
          </span>
        </div>
      )}

      {sites.length > 0 && filteredSiteList.length === 0 && (
        <div style={{ textAlign: "center", margin: "50px 0" }}>
          <Typography variant={"h6"} style={{ marginBottom: 30 }}>
            No sites were found with the keyword(s) <b>{filterKeyword}</b>.
          </Typography>
        </div>
      )}

      {filteredSiteList
        .sort((a, b) => {
          const domainA = a.attributes.domain.toUpperCase();
          const domainB = b.attributes.domain.toUpperCase();

          const titleA = a.attributes.title.toUpperCase();
          const titleB = b.attributes.title.toUpperCase();

          // return domainA - domainB || titleA - titleB;

          if (domainA === domainB) {
            return titleA < titleB ? -1 : 1;
          } else {
            return domainA < domainB ? -1 : 1;
          }
        })
        .map((site, key) => {
          return (
            <Card className={style.card} key={key}>
              <CardActionArea
                component={BrowserLink}
                to={
                  !site.attributes.reachedPlanLimit
                    ? `/sites/${site.attributes._id}`
                    : `/sites/${site.attributes._id}/plan`
                }
              >
                <CardContent style={{ padding: 0 }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="stretch"
                  >
                    <Grid item xs style={{ padding: 16 }}>
                      <Typography variant={"h6"}>
                        {site.attributes.title || "Untitled site"}

                        {site.attributes.platform === 2 && (
                          <>
                            <Tooltip
                              title={
                                "This website is on the WordPress Buttonizer Legacy plugin"
                              }
                            >
                              <img
                                src={FontAwesomeWordPress}
                                alt={"WordPress"}
                                className={style.wordPressIcon}
                              />
                            </Tooltip>{" "}
                            <small style={{ fontSize: 15 }}>
                              (legacy plugin)
                            </small>
                          </>
                        )}

                        {site.attributes.platform === 4 && (
                          <Tooltip
                            title={
                              "This website is on Buttonizer for WordPress"
                            }
                          >
                            <img
                              src={FontAwesomeWordPress}
                              alt={"WordPress"}
                              className={style.wordPressIcon}
                            />
                          </Tooltip>
                        )}

                        {site.attributes.platform === 3 && (
                          <Tooltip
                            title={"This website is on Buttonizer for Shopify"}
                          >
                            <img
                              src={FontAwesomeShopify}
                              alt={"Shopify"}
                              className={style.wordPressIcon}
                            />
                          </Tooltip>
                        )}
                      </Typography>
                      <Typography variant={"subtitle2"}>
                        {site.attributes.domain}
                      </Typography>
                    </Grid>
                    <Grid item style={{ width: 250 }}>
                      <SiteScreenshotOrUpgrade site={site} />
                    </Grid>
                  </Grid>
                </CardContent>
              </CardActionArea>

              <CardContent
                className={style.cardContent}
                style={{ borderTop: "1px solid #eeeeee" }}
              >
                <Grid container alignItems="center">
                  <Grid item>
                    {site.attributes.platform !== 2 && (
                      <Tooltip title={"Site plan"}>
                        <Button
                          size="small"
                          style={{
                            textTransform: "unset",
                            padding: "0  0 0 10px",
                            borderRadius: 10,
                          }}
                        >
                          <SitePlanBar
                            visitCounter={site.attributes.visitCounter}
                            planVisitLimit={site.attributes.visitorCountLimit}
                            isSitePremium={site.attributes.isPremium}
                            showPlanName
                            redirectTo={
                              site?.relationships?.license
                                ? `/sites/${site.attributes._id}/plan`
                                : `/sites/${site.attributes._id}/plan/list`
                            }
                          />
                        </Button>
                      </Tooltip>
                    )}
                  </Grid>

                  <Grid item xs style={{ textAlign: "right" }}>
                    {site.attributes.platform !== 2 &&
                      site.attributes.collectAnalytics && (
                        <Tooltip title={"Site analytics"}>
                          <IconButton
                            color={"primary"}
                            component={BrowserLink}
                            to={`/analytics/${site.attributes._id}`}
                            style={{ marginRight: 5 }}
                          >
                            <EqualizerIcon />
                          </IconButton>
                        </Tooltip>
                      )}

                    <Tooltip
                      title={
                        site.attributes.platform === 2
                          ? "Manage site on WordPress"
                          : "Site settings"
                      }
                    >
                      <IconButton
                        color={"secondary"}
                        component={BrowserLink}
                        to={`/sites/${site.attributes._id}/settings`}
                        style={{ marginRight: 5 }}
                      >
                        <SettingsIcon />
                      </IconButton>
                    </Tooltip>

                    {site.attributes.platform !== 2 ? (
                      <>
                        {/* <Button
                          color={"secondary"}
                          component={"a"}
                          href={siteToLink(site, true)}
                          size="small"
                        >
                          Open Beta editor
                        </Button> */}
                        <div className={style.spacer} />
                        <Button
                          color={"secondary"}
                          component={"a"}
                          href={siteToLink(site)}
                          size="small"
                        >
                          Open editor
                        </Button>
                      </>
                    ) : (
                      <Button
                        color={"secondary"}
                        component={"a"}
                        href={siteToLink(site)}
                      >
                        Edit buttons on WordPress
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          );
        })}

      {nextUrl && (
        <div style={{ textAlign: "center", paddingTop: 25 }}>
          <Button
            onClick={nextPage}
            disabled={isLoadingNextData || isSearching}
            variant={"contained"}
            color={"primary"}
            endIcon={
              (isLoadingNextData || isSearching) && (
                <CircularProgress size={25} />
              )
            }
          >
            Load more sites
          </Button>
        </div>
      )}
    </PageContainer>
  );
}
