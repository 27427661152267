import React, { Suspense, useRef, useState } from "react";
import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Popover,
  Tooltip,
} from "@mui/material";
import style from "./style.module.scss";
import { useAuth } from "context/Auth";
import { useSelector } from "react-redux";
import getResource from "store/helpers/getResource";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import BuzzyImage from "images/icon-head-only.svg";
import { Link as BrowserLink, useRouteMatch } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Loading from "components/Loading/Loading";

const SiteDropdownList = React.lazy(() =>
  import("./SiteDropdownList/SiteDropdownList")
);

function LogoComponent() {
  const isHomeMatch = useRouteMatch(["/overview"]);

  return (
    <div className={style.logo}>
      <Tooltip content={"Home"}>
        <ListItemButton
          component={BrowserLink}
          to={"/overview"}
          className={style.backBtn}
        >
          {!isHomeMatch && (
            <KeyboardArrowLeftRoundedIcon style={{ marginRight: 5 }} />
          )}

          <ListItemAvatar className={style.logo}>
            <Avatar src={BuzzyImage} />
          </ListItemAvatar>
        </ListItemButton>
      </Tooltip>
    </div>
  );
}

export default function SiteMenuItems() {
  const { currentSiteId } = useAuth();
  const [popperOpened, setPopperOpened] = useState(null);

  // Find primary license
  const site = useSelector((state) =>
    getResource(state, "sites", currentSiteId)
  );

  const siteSelected = currentSiteId !== null && site;
  const ref = useRef();

  return (
    <>
      <LogoComponent />
      <div className={style.divider} />
      <div className={style.siteSelector}>
        <ListItemButton
          onClick={(e) => {
            setPopperOpened(ref.current);
          }}
          ref={ref}
        >
          <ListItemText
            primary={
              <Tooltip title={site?.attributes?.title} ignorePreference>
                <span>
                  {siteSelected ? site.attributes.title : "No site selected"}
                </span>
              </Tooltip>
            }
            secondary={
              <Tooltip
                title={`https://${site?.attributes?.domain}`}
                ignorePreference
              >
                <span>
                  {siteSelected
                    ? `https://${site.attributes.domain}`
                    : "Select a site"}
                </span>
              </Tooltip>
            }
            primaryTypographyProps={{
              fontWeight: 500,
              fontSize: 15,
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
            secondaryTypographyProps={{
              fontSize: 13,
              color: "rgba(0, 0, 0, 0.3)",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
            style={{ margin: "2px 0" }}
          />
          <ArrowDropDownIcon />
        </ListItemButton>
      </div>

      <div className={style.whiteSpace} />

      <Popover
        open={popperOpened !== null}
        anchorEl={popperOpened}
        onClose={() => setPopperOpened(null)}
        data-testid={"site-dropdown"}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Suspense
          fallback={
            <div
              style={{
                padding: "20px 40px",
                minWidth: 200,
                textAlign: "center",
              }}
            >
              <Loading />
            </div>
          }
        >
          <SiteDropdownList
            close={() => setPopperOpened(null)}
            currentSiteId={site?.attributes?._id}
          />
        </Suspense>
      </Popover>
    </>
  );
}
