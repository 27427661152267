import React from "react";
import clsx from "clsx";
import style from "./style.module.scss";
import { useAuth } from "context/Auth";
import { useHistory } from "react-router-dom";
import { formatNumber } from "utils/forceNumber";

function daysUntilFirstOfMonth() {
  let dateToday = new Date();
  let lastDayOfMonth = new Date(
    dateToday.getFullYear(),
    dateToday.getMonth() + 1,
    0
  ).getDate();

  let daysUntilEndOfMonth = lastDayOfMonth - dateToday.getDate();

  return daysUntilEndOfMonth;
}

export default function SitePlanBar({
  visitCounter,
  planVisitLimit,
  isSitePremium,
  showPlanName = false,
  showCountBelow = false,
  fullWidth = false,
  redirectTo = false,
}) {
  const { isAgency } = useAuth();
  let history = useHistory();

  const hitLimit = visitCounter >= planVisitLimit;
  const usagePercentage = (visitCounter / planVisitLimit) * 100;

  return (
    <>
      <div
        className={clsx(
          style.container,
          showCountBelow && style.counterBelow,
          fullWidth && style.fullWidth
        )}
        onClick={() => {
          if (!redirectTo) return;
          history.push(redirectTo);
        }}
        style={{
          cursor: redirectTo ? "pointer" : "unset",
        }}
      >
        {showPlanName && (
          <div className={style.planName}>
            {isSitePremium
              ? isAgency
                ? "Agency plan"
                : "Pro plan"
              : "Free plan"}
          </div>
        )}
        {planVisitLimit !== "infinite" && (
          <div className={style.planBar}>
            <div className={style.planBarContainer}>
              <div
                className={clsx(
                  style.planBarState,
                  hitLimit && style.planBarReachedLimit
                )}
                style={{
                  width: `${usagePercentage <= 100 ? usagePercentage : 100}%`,
                }}
              />
            </div>
          </div>
        )}

        <div className={style.usage}>
          {formatNumber(visitCounter)}
          {(!isAgency || planVisitLimit !== "infinite") &&
            ` / ${formatNumber(planVisitLimit)}`}{" "}
          views this month
          <i className={clsx("fas fa-question-circle", style.questionmark)} />
        </div>
      </div>
    </>
  );
}
