import React, { useState } from "react";
import style from "../style.module.scss";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  TextField,
} from "@mui/material";
import { Link as BrowserLink, useHistory } from "react-router-dom";
import { register } from "utils/auth";
import MimicSiteContainer from "components/MimicSiteContainer/MimicSiteContainer";
import { useUser } from "context/Auth";

export default function CreateAccount() {
  const user = useUser();
  const history = useHistory();

  // Fields
  const [createAccountError, setCreateAccountError] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [repeatPasswordError, setRepeatPasswordError] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptTermsError, setAcceptTermsError] = useState(false);

  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [isAccountCreated, setIsAccountCreated] = useState(false);

  // Send user their site overview
  if (user) {
    history.push("/overview");

    return null;
  }

  const createAccount = (e = null) => {
    if (e) {
      e.preventDefault();
    }

    // Make sure all fields are filled in
    if (firstName === "" || email === "" || password === "") {
      setCreateAccountError("Some fields are required.");
      return;
    }

    // Check password repeated
    if (password !== repeatPassword || repeatPassword === "") {
      setCreateAccountError("Your passwords didn't match.");
      setRepeatPasswordError(true);
      return;
    }

    // Make sure the password it at least 8 characters long
    if (password.length < 8) {
      setCreateAccountError("Password must be at least 8 characters long.");
      return;
    }

    // Accept terms
    if (!acceptTerms) {
      setCreateAccountError(
        "You must accept our Terms & Conditions and Privacy Policy."
      );

      setAcceptTermsError(true);
      return;
    }

    setIsCreatingAccount(true);

    setCreateAccountError(null);

    register({
      firstName,
      lastName,
      email,
      password,
    })
      .then(() => setIsAccountCreated(true))
      .catch((error) => {
        setIsCreatingAccount(false);
        setCreateAccountError(error);
      });
  };

  const authPath =
    window.buttonizerStartLocation &&
    window.buttonizerStartLocation.indexOf("/auth/authorize") >= 0
      ? window.buttonizerStartLocation
      : "/auth/login";

  // Creating account successful
  if (isAccountCreated) {
    return (
      <MimicSiteContainer createAccountActive={true}>
        <div
          className={style.welcomeContainer}
          data-testid={"welcome-container"}
        >
          <div className={style.welcomeCard}>
            <h1>Welcome, {firstName}</h1>
            <p>
              We have send a confirmation email to <b>{email}</b>. Please
              confirm your email address before signing in.
            </p>

            <p>
              After activating your account, you will be able to sign in to
              Buttonizer.
            </p>

            <Button
              to={authPath}
              component={BrowserLink}
              color={"secondary"}
              fullWidth
            >
              Start signing in
            </Button>
          </div>
        </div>
      </MimicSiteContainer>
    );
  }

  return (
    <MimicSiteContainer>
      <div className={style.welcomeContainer}>
        {/* <img src={logo} className={style.logo} alt={"Buttonizer Logo"} /> */}

        <div className={style.welcomeCard}>
          <h1>Create an account</h1>
          <p>
            Are you ready to use Buttonizer? Create an account by filling in all
            the required fields.
          </p>

          {createAccountError && (
            <div className={style.error}>{createAccountError}</div>
          )}

          <form onSubmit={createAccount}>
            <Grid
              container
              spacing={1}
              style={{ marginTop: 15 }}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Grid item xs={12} sm={6}>
                <TextField
                  variant={"outlined"}
                  className={style.input}
                  fullWidth
                  label={"First name"}
                  placeholder={"Jane"}
                  required
                  disabled={isCreatingAccount}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  error={createAccountError && firstName === ""}
                  inputProps={{
                    "data-testid": "first-name-field",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
                <TextField
                  variant={"outlined"}
                  className={style.input}
                  fullWidth
                  label={"Last name"}
                  placeholder={"Doe"}
                  disabled={isCreatingAccount}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  inputProps={{
                    "data-testid": "last-name-field",
                  }}
                />
              </Grid>
            </Grid>

            <TextField
              variant={"outlined"}
              className={style.input}
              fullWidth
              required
              label={"Email address"}
              placeholder={"your@example.com"}
              disabled={isCreatingAccount}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={createAccountError && email === ""}
              inputProps={{
                "data-testid": "email-field",
              }}
            />

            <TextField
              variant={"outlined"}
              className={style.input}
              fullWidth
              required
              type={"password"}
              label={"Password"}
              placeholder={"Enter your password"}
              disabled={isCreatingAccount}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={createAccountError && password === ""}
              inputProps={{
                "data-testid": "password-field",
              }}
            />

            <TextField
              variant={"outlined"}
              className={style.input}
              fullWidth
              required
              type={"password"}
              label={"Repeat password"}
              placeholder={"Repeat your password"}
              disabled={isCreatingAccount}
              value={repeatPassword}
              error={
                repeatPasswordError ||
                (createAccountError && repeatPassword === "")
              }
              onChange={(e) => setRepeatPassword(e.target.value)}
              inputProps={{
                "data-testid": "repeat-password-field",
              }}
            />

            <FormControl required error={acceptTermsError} component="fieldset">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={acceptTerms}
                    onChange={() => setAcceptTerms(!acceptTerms)}
                    name="accept_tos"
                    disabled={isCreatingAccount}
                    inputProps={{
                      "data-testid": "accept-privacy-and-tos",
                    }}
                  />
                }
                label={
                  <span>
                    I accept the{" "}
                    <Link
                      href={"https://buttonizer.pro/privacy-policy/"}
                      target={"_blank"}
                    >
                      Privacy Policy
                    </Link>{" "}
                    and{" "}
                    <Link
                      href={"https://buttonizer.pro/terms-conditions/"}
                      target={"_blank"}
                    >
                      Terms &amp; Conditions
                    </Link>
                  </span>
                }
              />

              {acceptTermsError && (
                <FormHelperText>{createAccountError}</FormHelperText>
              )}
            </FormControl>

            <Grid
              container
              spacing={1}
              style={{ marginTop: 5 }}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Grid item xs={12} sm={6}>
                <Button
                  to={authPath}
                  component={BrowserLink}
                  color={"secondary"}
                  fullWidth
                >
                  I already have an account
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  size={"large"}
                  disabled={isCreatingAccount}
                  endIcon={
                    isCreatingAccount ? (
                      <CircularProgress
                        size={20}
                        thickness={5}
                        style={{ marginLeft: 10 }}
                      />
                    ) : null
                  }
                  onClick={createAccount}
                  type={"submit"}
                  data-testid={"create-account-button"}
                >
                  Create account
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </MimicSiteContainer>
  );
}
