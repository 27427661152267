import React, { useEffect } from "react";
import { useHistory } from "react-router";

export default function ScrollToTopOnClick({ children }) {
  const history = useHistory();

  useEffect(() => {
    let onPageChangeTimeout = setTimeout(() => {}, 100);

    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);

      // Track page view
      if (typeof window._paq !== "undefined") {
        window._paq.push(["setCustomUrl", window.location.pathname]);
        window._paq.push(["trackPageView"]);
      }

      // Reset Formilla widgets
      if (window.Formilla) {
        onPageChangeTimeout = setTimeout(() => {
          window.Formilla.Unload();
          window.Formilla.loadWidgets();
        }, 300);
      }
    });

    return () => {
      unlisten();
      clearTimeout(onPageChangeTimeout);
    };
  }, []);

  return children;
}
