import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
  TextField,
  IconButton,
  Typography,
} from "@mui/material";
import { useAuth } from "context/Auth";
import React, { useState } from "react";
import api from "utils/api";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import getResponseErrorMessage from "utils/getResponseErrorMessage";

export default function OnboardSetPasswordDialog({ onClose }) {
  const { user, setRequiresPasswordReset } = useAuth();
  const [isUpdating, setIsUpdating] = useState(false);
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [error, setError] = useState(false);
  const [repeatNewPasswordError, setRepeatNewPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const updatePassword = (e) => {
    e.preventDefault();

    // Empty fields?
    if (password === "" || repeatPassword === "") {
      setError(true);
      return;
    }

    // Validate password match
    if (password !== repeatPassword) {
      setRepeatNewPasswordError(true);
      return;
    }

    setIsUpdating(true);

    api("/account/onboarding/password", {
      password,
    })
      .then(() => {
        setRequiresPasswordReset(false);
      })
      .catch((e) => {
        setError(getResponseErrorMessage(e));
        setIsUpdating(false);
      });
  };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="delete-website-dialog"
      style={{ zIndex: 5000 }}
    >
      <DialogTitle id="delete-website-dialog">
        Hey {user.attributes.firstName}!
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Welcome to Buttonizer, you will need to set a password for your new
          account. Choose wisely, security is important.
        </DialogContentText>

        {error && (
          <Typography
            variant={"body1"}
            color={"error"}
            style={{ marginTop: 15 }}
          >
            {error}
          </Typography>
        )}

        <form onSubmit={updatePassword}>
          <TextField
            variant={"outlined"}
            fullWidth
            type={showPassword ? "text" : "password"}
            label={"Password"}
            placeholder={"Enter a password"}
            disabled={isUpdating}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={error && password === ""}
            autoFocus
            InputProps={{
              endAdornment: (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  size="large"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              ),
            }}
            required
            helperText={
              <>Your new password should be at least 8 characters long.</>
            }
            style={{ marginTop: 15, marginBottom: 15 }}
            inputProps={{
              "data-testid": "password-field",
            }}
          />
          <TextField
            variant={"outlined"}
            fullWidth
            required
            type={"password"}
            label={"Repeat your password"}
            placeholder={"Repeat your password"}
            disabled={isUpdating}
            value={repeatPassword}
            error={repeatNewPasswordError || (error && repeatPassword === "")}
            onChange={(e) => setRepeatPassword(e.target.value)}
            style={{ marginBottom: 15 }}
            inputProps={{
              "data-testid": "password-repeat-field",
            }}
          />

          <input type={"submit"} style={{ display: "none" }} />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={updatePassword}
          disabled={isUpdating}
          color="secondary"
        >
          Set password
          {isUpdating && (
            <CircularProgress size={20} style={{ marginLeft: 10 }} />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
