import React from "react";
import style from "../style.module.scss";
import { Button } from "@mui/material";
import { Link as BrowserLink } from "react-router-dom";
import { useUser } from "context/Auth";
import MimicSiteContainer from "components/MimicSiteContainer/MimicSiteContainer";

export default function EmailVerified() {
  const user = useUser();

  const content = (
    <div className={style.welcomeContainer}>
      <div className={style.welcomeCard}>
        <h1>Account activated!</h1>
        <p>
          Thank you for verifying your email. You can now sign into the
          Buttonizer Dashboard with your account.
        </p>

        <p>
          If you run into any trouble or have questions, please let us know :)
        </p>

        {!user && (
          <Button
            to={"/auth/login"}
            component={BrowserLink}
            color={"secondary"}
            fullWidth
          >
            Start signing in
          </Button>
        )}
      </div>
    </div>
  );

  // If logged in, show content without header
  if (user) {
    return content;
  }

  return <MimicSiteContainer>{content}</MimicSiteContainer>;
}
