import { CircularProgress, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { formatNumber } from "utils/forceNumber";

export default function SitePlanCircular({
  visitCounter,
  planVisitLimit,
  small = false,
  noMargin = false,
  ...props
}) {
  const hitLimit = visitCounter >= planVisitLimit;
  const usagePercentage = (visitCounter / planVisitLimit) * 100;

  return (
    <Tooltip
      title={`${formatNumber(visitCounter)} / ${formatNumber(
        planVisitLimit
      )} shared plan views this month`}
      arrow
    >
      <Box
        sx={{ position: "relative", display: "inline-flex" }}
        style={{
          marginRight: small ? (noMargin ? undefined : "15px") : "10px",
          position: "relative",
        }}
      >
        <CircularProgress
          variant="determinate"
          color={hitLimit ? "error" : "secondary"}
          value={usagePercentage >= 100 ? 100 : usagePercentage}
          size={small ? 30 : 45}
          thickness={small ? 4 : 2.5}
          style={{ zIndex: 2 }}
        />

        <CircularProgress
          variant="determinate"
          color={"gray"}
          value={100}
          size={small ? 30 : 45}
          thickness={small ? 4 : 2.5}
          style={{ position: "absolute", zIndex: 1 }}
        />

        {!small && (
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="caption"
              component="div"
              color="text.secondary"
            >
              {`${usagePercentage >= 100 ? 100 : Math.round(usagePercentage)}%`}
            </Typography>
          </Box>
        )}
      </Box>
    </Tooltip>
  );
}
