import React, { useState } from "react";
import style from "../style.module.scss";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { Link as BrowserLink, useParams } from "react-router-dom";
import api from "utils/api";
import getResponseErrorMessage from "utils/getResponseErrorMessage";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useUser } from "context/Auth";
import { useHistory } from "react-router-dom";
import MimicSiteContainer from "components/MimicSiteContainer/MimicSiteContainer";

export default function RecoverReset() {
  const { token } = useParams();
  const history = useHistory();
  const user = useUser();

  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");
  const [repeatNewPasswordError, setRepeatNewPasswordError] = useState(false);
  const [resetSuccessful, setResetSuccessful] = useState(false);

  // If user is logged in
  if (user !== null) {
    history.push("/overview");

    return null;
  }

  const reset = (e = null) => {
    if (e) {
      e.preventDefault();
    }

    // Already requesting
    if (isResettingPassword) return;

    // Make sure all fields are filled in
    if (newPassword === "") {
      setErrorMessage("All fields are required.");
      return;
    }

    // Check password repeated
    if (newPassword !== repeatNewPassword || repeatNewPassword === "") {
      setErrorMessage("Your passwords didn't match.");
      setRepeatNewPasswordError(true);
      return;
    }

    // Empty field
    if (newPassword === "") {
      setErrorMessage("Field is empty");
      return;
    }

    // Make sure the password it at least 8 characters long
    if (newPassword.length < 8) {
      setErrorMessage("Password must be at least 8 characters long.");
      return;
    }

    setIsResettingPassword(true);

    api("/auth/recover/password", {
      password: newPassword,
      token,
    })
      .then(() => setResetSuccessful(true))
      .catch((e) => setErrorMessage(getResponseErrorMessage(e)))
      .then(() => setIsResettingPassword(false));
  };

  // Resetting password successful
  if (resetSuccessful) {
    return (
      <MimicSiteContainer>
        <div className={style.welcomeContainer}>
          <div className={style.welcomeCard}>
            <h1>Reset succesfull</h1>
            <p>
              Your password has been reset. You can now continue to sign in with
              your new password.
            </p>

            <Button
              to={"/auth/login"}
              component={BrowserLink}
              color={"secondary"}
              fullWidth
            >
              Start signing in
            </Button>
          </div>
        </div>
      </MimicSiteContainer>
    );
  }

  return (
    <MimicSiteContainer>
      <div className={style.welcomeContainer}>
        <div className={style.welcomeCard}>
          <h1>Reset password</h1>

          <p>You are about to change your Buttonizer password.</p>

          {errorMessage && <div className={style.error}>{errorMessage}</div>}

          <form onSubmit={reset}>
            <TextField
              variant={"outlined"}
              className={style.input}
              fullWidth
              type={showPassword ? "text" : "password"}
              label={"New password"}
              placeholder={"Enter your new password"}
              disabled={isResettingPassword}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              error={errorMessage && newPassword === ""}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                ),
              }}
            />

            <TextField
              variant={"outlined"}
              className={style.input}
              fullWidth
              required
              type={"password"}
              label={"Repeat new password"}
              placeholder={"Repeat your new password"}
              disabled={isResettingPassword}
              value={repeatNewPassword}
              error={
                repeatNewPasswordError ||
                (errorMessage && repeatNewPassword === "")
              }
              onChange={(e) => setRepeatNewPassword(e.target.value)}
            />

            <Grid
              container
              spacing={1}
              style={{ marginTop: 5 }}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Grid item xs={12} sm={6}>
                <Button
                  to={"/auth/login"}
                  component={BrowserLink}
                  color={"secondary"}
                  fullWidth
                >
                  I remember my credentials
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  size={"large"}
                  disabled={isResettingPassword}
                  endIcon={
                    isResettingPassword ? (
                      <CircularProgress
                        size={20}
                        thickness={5}
                        style={{ marginLeft: 10 }}
                      />
                    ) : null
                  }
                  onClick={reset}
                  type={"submit"}
                >
                  Finish
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </MimicSiteContainer>
  );
}
