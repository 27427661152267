import {
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  CircularProgress,
  ListItemText,
  ListItemButton,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import React, { useState } from "react";
import style from "./style.module.scss";
import { Link } from "react-router-dom";
import { useUser, useAuth } from "context/Auth";
import Grow from "@mui/material/Grow";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import StorefrontIcon from "@mui/icons-material/Storefront";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import SupportIcon from "@mui/icons-material/Support";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { logout } from "utils/auth";
import api from "utils/api";
import getResponseErrorMessage from "utils/getResponseErrorMessage";
import clsx from "clsx";

export default function AccountMenu() {
  const user = useUser();
  const auth = useAuth();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  // Close account menu
  const handleAccountClose = () => {
    setAnchorEl(null);
  };

  // Start impersonating a user
  const impersonateUser = () => {
    const email = prompt(
      "What is the email of the user you would like to impersonate? After hitting enter you'll be switched immediately."
    );

    if (email) {
      setIsLoggingOut(true);

      api("/support_tools/impersonate", {
        email: email.toLowerCase(),
      })
        .then(() => {
          document.location.href = "/overview";
        })
        .catch((e) => alert(getResponseErrorMessage(e)))
        .then(() => setIsLoggingOut(false));
    }
  };

  // Sign out
  const signOutAction = () => {
    if (isLoggingOut) return;

    setIsLoggingOut(true);

    // User is impersonating, exit impersonating
    if (auth.isImpersonating) {
      api("/support_tools/impersonate/exit").then(() => {
        document.location.href = "/overview";
      });

      return;
    }

    // Log out, remove token and redirect
    logout().then(() => (document.location.href = "/"));
  };

  return (
    <>
      <ListItemButton
        className={style.accountButton}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        sx={{
          display: "inline-flex",
          borderRadius: "5px",
          padding: "5px 8px",
          marginLeft: "10px",
        }}
      >
        <ListItemAvatar className={style.avatar} style={{ minWidth: 36 }}>
          <Avatar src={user.attributes.avatarUrl} />
        </ListItemAvatar>
        <ListItemText
          primary={`${user.attributes.firstName} ${user.attributes.lastName}`}
          secondary={auth.isImpersonating && `Impersonating`}
          primaryTypographyProps={{
            sx: {
              fontSize: "12px",
              letterSpacing: "0.00938em",
              color: "#717171",
            },
          }}
          secondaryTypographyProps={{
            sx: {
              fontSize: "10px",
              letterSpacing: "0.00938em",
              color: "#717171",
            },
          }}
          className={style.fullName}
        />

        <KeyboardArrowDownRoundedIcon style={{ marginLeft: 5 }} />
      </ListItemButton>

      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleAccountClose}
        TransitionComponent={Grow}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 5px rgba(0,0,0,0.1))",
            minWidth: 250,
            mt: 0,
            "& .MuiListItemText-secondary": {
              fontSize: 13,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          component={Link}
          to={"/account"}
          onClick={handleAccountClose}
          disabled={isLoggingOut}
        >
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          My account
        </MenuItem>
        {!auth.isAppSumoCustomer ? (
          <MenuItem
            component={Link}
            to={auth.isAgency ? "/plan" : "/account/subscriptions"}
            onClick={handleAccountClose}
            disabled={isLoggingOut}
          >
            <ListItemIcon>
              <CardMembershipIcon fontSize="small" />
            </ListItemIcon>

            {auth.isAgency ? "Agency plan" : "My subscriptions"}
          </MenuItem>
        ) : (
          <MenuItem
            component={Link}
            to={"/plan"}
            onClick={handleAccountClose}
            disabled={isLoggingOut}
          >
            <ListItemIcon>
              <CardMembershipIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={"My plan"} secondary={"AppSumo plan"} />
          </MenuItem>
        )}
        <MenuItem
          component={Link}
          to={"/support"}
          onClick={handleAccountClose}
          disabled={isLoggingOut}
        >
          <ListItemIcon>
            <SupportIcon fontSize="small" />
          </ListItemIcon>
          Support
        </MenuItem>
        {/* <Divider /> */}
        {/* <MenuItem
          component={Link}
          to={"/affiliate"}
          onClick={handleAccountClose}
          disabled={isLoggingOut}
        >
          <ListItemIcon>
            <CurrencyExchangeIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={
              user && user.attributes.affiliateId
                ? "Affiliate dashboard"
                : "Become an affiliate"
            }
            secondary={user && !user.attributes.affiliateId && "and earn $"}
          />
        </MenuItem> */}
        <Divider />

        {auth.canImpersonate && !auth.isImpersonating && (
          <MenuItem onClick={impersonateUser} disabled={isLoggingOut}>
            <ListItemIcon>
              {isLoggingOut ? (
                <CircularProgress size={20} />
              ) : (
                <SwitchAccountIcon fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText
              primary={"Impersonate user"}
              secondary={isLoggingOut ? "Impersonating..." : "As support agent"}
            />
          </MenuItem>
        )}
        {auth.canImpersonate && !auth.isImpersonating && <Divider />}

        {!auth.isImpersonating && (
          <MenuItem onClick={signOutAction} disabled={isLoggingOut}>
            <ListItemIcon>
              {isLoggingOut ? (
                <CircularProgress size={20} />
              ) : (
                <LogoutIcon fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText
              primary={isLoggingOut ? "Logging out..." : "Logout"}
            />
          </MenuItem>
        )}

        {auth.isImpersonating && (
          <MenuItem onClick={signOutAction} disabled={isLoggingOut}>
            <ListItemIcon>
              {isLoggingOut ? (
                <CircularProgress size={20} />
              ) : (
                <LogoutIcon fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText
              primary={"Exiting impersonating"}
              secondary={isLoggingOut ? "Exiting..." : "Back to your account"}
            />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
