import { useUser } from "context/Auth";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import dlv from "dlv";
import style from "./style.module.scss";
import {
  Button,
  CircularProgress,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { domainIsValid, getDomainFromValue } from "utils/domain";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import api from "utils/api";
import { setToken } from "utils/auth";
import Image from "../../../images/onboarding-background-2023.png";
import getResponseErrorMessage from "utils/getResponseErrorMessage";

export default function Intake() {
  const routeParams = queryString.parse(document.location.search);
  const user = useUser();
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState(null);
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [formSend, setFormSend] = useState(false);
  const [domain, setDomain] = useState(
    dlv(routeParams, "domain", "")
      .replace("https://", "")
      .replace("http://", "")
  );
  const [isStepTwo, setStepTwo] = useState(
    dlv(routeParams, "domain", "") !== "" && domainIsValid(domain)
  );
  const [domainError, setDomainError] = useState(false);
  const [email, setEmail] = useState(dlv(routeParams, "email", ""));
  const [firstName, setFirstName] = useState("");

  // Update domain
  const updateDomainValue = (val) => {
    const domainName = getDomainFromValue(val);

    setDomainError(!domainIsValid(domainName) || domainName === "");
    setDomain(domainName);
  };

  const finishOnboarding = () => {
    setIsCreatingAccount(true);

    api("/account/onboarding/new", {
      firstName,
      email,
      title: domain,
      domain: domain,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || "UTC",
    })
      .then(({ data }) => {
        setToken(data.token);

        setTimeout(() => {
          document.location.href = `${process.env.REACT_APP_BUZZY_EDITOR_URL}${data.data.id}#/welcome-splash`;
        }, 500);
      })
      .catch((e) => {
        setErrorMessage(getResponseErrorMessage(e));
        setIsCreatingAccount(false);
      });
  };

  // Send user their site overview
  if (user) {
    history.push("/sites/new");

    return null;
  }

  // ?domain=yourdomain.com
  return (
    <div className={style.container}>
      <img src={Image} alt={"Buttonizer"} className={style.containerImage} />
      <div className={style.intakeContainer}>
        <Paper className={style.intake}>
          {!isStepTwo && (
            <form
              onSubmit={(e) => {
                e.preventDefault();

                if (!domain === "" || !domainIsValid(domain)) {
                  setDomainError(true);

                  return;
                }

                setStepTwo(true);
              }}
            >
              <Typography variant={"h4"} className={style.title}>
                Welcome, buzzy!
              </Typography>
              <Typography variant={"body1"} className={style.content}>
                We are ready to onboard you! On what domain would you like to
                use Buttonizer?
              </Typography>

              <TextField
                variant={"outlined"}
                className={style.input}
                fullWidth
                label={"Domain name"}
                placeholder={"Enter your domain name"}
                style={{ marginTop: 15 }}
                disabled={isCreatingAccount}
                value={domain}
                onChange={(e) => updateDomainValue(e.target.value)}
                onPaste={(e) => updateDomainValue(e.target.value)}
                inputProps={{
                  "data-testid": "domain-field",
                }}
                autoFocus
                error={domainError}
                helperText={
                  <>
                    Only enter your domain name. We'll take care of the rest.
                    Subdomains are supported too.
                  </>
                }
                required
              />

              <div className={style.footerButtons}>
                <Button
                  variant={"contained"}
                  color={"secondary"}
                  type={"submit"}
                >
                  Continue
                </Button>
              </div>
            </form>
          )}

          {isStepTwo && (
            <form
              onSubmit={(e) => {
                e.preventDefault();

                if (email === "" || firstName === "") {
                  setFormSend(true);

                  return;
                }

                finishOnboarding();
              }}
            >
              <Typography variant={"h4"} className={style.title}>
                Add Buttonizer for free
              </Typography>

              {errorMessage && (
                <Typography
                  variant={"body1"}
                  color={"error"}
                  className={style.content}
                >
                  {errorMessage}
                </Typography>
              )}

              <TextField
                variant={"outlined"}
                className={style.input}
                autoFocus
                fullWidth
                label={"First name"}
                placeholder={"Enter your first name"}
                style={{ marginTop: 15 }}
                disabled={isCreatingAccount}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                inputProps={{
                  "data-testid": "first-name-field",
                }}
                error={formSend && firstName === ""}
                InputProps={{
                  startAdornment: (
                    <AccountCircleOutlinedIcon className={style.formIcon} />
                  ),
                }}
                required
              />

              <TextField
                variant={"outlined"}
                className={style.input}
                fullWidth
                label={"Email"}
                placeholder={"Enter your email"}
                style={{ marginTop: 15 }}
                disabled={isCreatingAccount}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                inputProps={{
                  "data-testid": "email-field",
                }}
                error={formSend && email === ""}
                InputProps={{
                  startAdornment: (
                    <MailOutlineIcon className={style.formIcon} />
                  ),
                }}
                required
              />

              <div style={{ marginTop: 15 }}>
                <Typography variant={"subtitle2"}>
                  We'll add <b>{domain}</b> to your account.
                  <br />
                  <Link
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();

                      setStepTwo(false);
                    }}
                    color={"primary"}
                  >
                    Change domain.
                  </Link>
                </Typography>
              </div>

              <div style={{ padding: "20px 0 5px" }}>
                <Typography variant={"body2"}>
                  By clicking on 'Get started', you have agreed that we will
                  create a new account for you and that you have read and accept
                  our{" "}
                  <Link
                    href={"https://buttonizer.io/privacy-policy/"}
                    target={"_blank"}
                  >
                    Privacy Policy
                  </Link>{" "}
                  and{" "}
                  <Link
                    href={"https://buttonizer.io/terms-conditions/"}
                    target={"_blank"}
                  >
                    Terms &amp; Conditions
                  </Link>
                  .
                </Typography>
              </div>

              <div className={style.footerButtons}>
                <Button
                  variant={"contained"}
                  color={"secondary"}
                  disabled={isCreatingAccount}
                  type={"submit"}
                  endIcon={
                    isCreatingAccount && (
                      <CircularProgress
                        size={20}
                        thickness={5}
                        style={{ marginLeft: 5 }}
                      />
                    )
                  }
                >
                  Get Started
                </Button>
              </div>
            </form>
          )}
        </Paper>
      </div>
    </div>
  );
}
