import React from "react";
import style from "./style.module.scss";
import Header from "components/Header/Header";
import clsx from "clsx";

export default function MimicSiteContainer({
  loginActive = false,
  withHeader = true,
  children,
}) {
  return (
    <>
      {withHeader && <Header />}

      {!withHeader && (
        <div
          className={clsx(style.container, loginActive && style.loginActive)}
        >
          <div style={{ width: "100%" }}>{children}</div>
        </div>
      )}
      {withHeader && children}

      <div className={style.bubbleDecoration}>
        <div className={style.bubbleLeft} />
        <div className={style.bubbleRight} />
        <div className={style.bubbleBottom} />
      </div>
    </>
  );
}
