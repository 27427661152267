import React, { useEffect, useState } from "react";
import style from "../style.module.scss";
import { Button, CircularProgress } from "@mui/material";
import { Link as BrowserLink, useHistory } from "react-router-dom";
import { useUser } from "context/Auth";
import MimicSiteContainer from "components/MimicSiteContainer/MimicSiteContainer";
import { authByEmail } from "utils/auth";
import getSearchParams from "utils/getSearchParams";

export default function MagicLogin() {
  const [isLoggingIn, setIsLoggingIn] = useState(true);
  const user = useUser();
  const params = getSearchParams();
  const history = useHistory();

  useEffect(() => {
    authByEmail(document.location.search)
      .then(
        () =>
          (document.location.href = !params.redirect_path
            ? `/`
            : `/${params.redirect_path}`)
      )
      .catch(() => setIsLoggingIn(false));
  }, []);

  if (user) {
    history.push("/overview");
    return null;
  }

  const errorContent = (
    <div className={style.welcomeContainer}>
      <div className={style.welcomeCard}>
        <h1>Link expired</h1>
        <p>
          Sorry, this link was expired or has already been used. Please request
          a new one.
        </p>

        <p>
          If you run into any trouble or have questions, please let us know :)
        </p>

        {!user && (
          <Button
            to={"/auth/login"}
            component={BrowserLink}
            color={"secondary"}
            fullWidth
          >
            Back to login
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <MimicSiteContainer withHeader={isLoggingIn === false}>
      {isLoggingIn ? (
        <div className={style.welcomeContainer} style={{ textAlign: "center" }}>
          <div className={style.welcomeCard}>
            <h1>Signing you in</h1>
            <p>
              We're working on some magic spells and sign you in. Please allow
              us a short moment...
            </p>

            <div style={{ paddingTop: 50, paddingBottom: 25 }}>
              <CircularProgress size={60} />
            </div>
          </div>
        </div>
      ) : (
        errorContent
      )}
    </MimicSiteContainer>
  );
}
